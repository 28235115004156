/* eslint-disable react-hooks/exhaustive-deps */
import { CircularProgress, Dialog, TextField, Tooltip } from "@mui/material";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Button from "../../../components/button";
import { saveAs } from "file-saver";
import {
  addMessageToSession,
  deleteConversations,
  getChatHistory,
  getInitialChildSavedStatus,
  saveAiChildRecommendations,
  shareRecommendations,
  startConversation,
} from "../../../apis/recommendation/recommendation";
import { toast } from "react-toastify";
import { debounce } from "lodash";
import { checkAndRefreshToken } from "../../../utils/authUtils";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../../../components/layout/layout";
import MarkdownComponent from "../../../components/MarkdownComponent";
import { getUserNames } from "../../../utils";
import SioIcon from "../../../components/icons/IconLibrary";
import SioTooltipClampedText from "../../../components/TooltipClampedText";

const SuggestionsContainer = ({
  recommendationData,
  fileUUID,
  isDataLoading,
}) => {
  const recId = useRef(recommendationData?.id);
  const [recData, setRecData] = useState(null);
  const divRef = useRef(null);
  const [scrollToTop, setScrollToTop] = useState(false);
  const [page, setPage] = useState(1);
  const [aiChildRecommendations, setAiChildRecommendations] = useState(null);
  const [messageId, setMessageId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [savedAiChild, setSavedAiChild] = useState(null);
  const lastPage = useRef(null);
  const [userId, setUserId] = useState(null);
  // const [updatedSessionId, setUpdateSessionId] = useState(null);
  const updatedSessionId = useRef(null);
  const [openRemoveModal, setRemoveModalOpen] = useState(false);

  const handleRemoveModalClose = () => {
    setRemoveModalOpen(false);
  };

  const handleRemoveModalClickOpen = (item) => {
    setRemoveModalOpen(true);
  };

  const navigate = useNavigate();
  const {
    setDataFromChildren,
    APIData,
    setAPIData,
    isPromptResponseGenerated,
    setIsPromptResponseGenerated,
  } = useContext(DataContext);

  const fullUrl = window.location.href;
  const modifiedURL = fullUrl.split("/").slice(0, 3).join("/");

  // const handleClose = () => {
  //   // setAnchorEl(null);
  // };

  const handleShare = async () => {
    const { shareable_url } = await shareRecommendations(
      recId.current,
      modifiedURL
    );
    const emailShareUrl = `mailto:?subject=Check out this data!&body=Check out this link: ${shareable_url}`;
    window.location.href = emailShareUrl;
  };

  const computeDateTime = (updated) => {
    let hours = updated.getHours();

    const minutes = updated.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;
    hours = hours ? hours : 12;

    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

    const time = `${hours}:${formattedMinutes} ${ampm}`;
    return time;
  };

  const calculateTime = (date) => {
    if (!date) return null;
    const today = new Date();
    const updated = new Date(date);
    const updatedMonth = updated.toLocaleString("default", { month: "short" });
    const updatedDate = updated.getDate();
    const todayMonth = today.toLocaleString("default", { month: "short" });
    const todayDate = today.getDate();
    let recommendationDate;
    if (todayMonth === updatedMonth) {
      if (todayDate - updatedDate >= 1) {
        recommendationDate = `${updatedMonth} ${updatedDate}, ${computeDateTime(
          updated
        )}`;
      } else if (todayDate - updatedDate === 0) {
        recommendationDate = computeDateTime(updated);
      }
    } else {
      recommendationDate = computeDateTime(updated);
    }
    return recommendationDate;
  };

  const handleDownload = (data) => {
    const prompt = data.find((item) => item.input)?.input?.content || "";
    const fileContent = data
      .map((item, index) => {
        const inputText = item.input?.content
          ? `Input:\n${item.input?.content}`
          : "";
        const responseText = item.response ? `Response:\n${item.response}` : "";

        return (
          inputText + (inputText && responseText ? "\n\n" : "") + responseText
        );
      })
      .join("\n\n\n");

    const blob = new Blob([fileContent], { type: "text/plain;charset=utf-8" });
    saveAs(blob, `${prompt}.txt`);
  };

  const fetchChatHistory = async (
    page = 1,
    perPage = 10,
    previousScrollHeight,
    previousScrollTop
  ) => {
    const token = await checkAndRefreshToken();
    if (!token) navigate("/login");

    const nextQueryParams = new URLSearchParams();
    nextQueryParams.append("recommendation_id", recId.current);
    nextQueryParams.append("page", page);
    nextQueryParams.append("per_page", perPage);

    let historyData;
    if (!userId) {
      const { data } = await getUserNames();
      nextQueryParams.append("user_id", data?.user?.id);
    } else {
      nextQueryParams.append("user_id", userId);
    }

    try {
      const { history, total_pages } = await getChatHistory(
        nextQueryParams,
        token
      );
      lastPage.current = total_pages;
      if (history && history.length > 0) {
        // setUpdateSessionId(history[0].session_id);
        updatedSessionId.current = history[0].session_id;
        let pushData = [];
        history.forEach((value) => {
          pushData.unshift(
            value.type === "ai"
              ? {
                  response: value?.content,
                  id: value?.id,
                  likes: value?.likes,
                  disLikes: value?.dislikes,
                  created_at: value?.created_at,
                  qualitative_saved: value?.qualitative_saved,
                  quantitative_saved: value?.quantitative_saved,
                }
              : {
                  input: { content: value?.content, id: value?.id },
                  id: value?.id,
                  likes: value?.likes,
                  disLikes: value?.dislikes,
                  created_at: value?.created_at,
                  saved_status: value?.saved_status,
                  qualitative_saved: value?.qualitative_saved,
                  quantitative_saved: value?.quantitative_saved,
                }
          );
        });
        setRecData((prevArray) => [...pushData, ...prevArray]);
        if (
          history &&
          history.length > 0 &&
          history[history.length - 1].qualitative_question !== null &&
          history[history.length - 1].quantitative_question !== null
        ) {
          const aiRec =
            history && history.length > 0
              ? [
                  history[history.length - 1].qualitative_question,
                  history[history.length - 1].quantitative_question,
                  history[history.length - 1].qualitative_saved,
                  history[history.length - 1].quantitative_saved,
                ]
              : [];
          setAiChildRecommendations(aiRec);
          setMessageId(history[history.length - 1].id);
        }

        setRecData((prevArray) => {
          const filtered = prevArray.filter(
            (items) => items?.LoadingData !== "LoadingOldChat"
          );
          return filtered;
        });

        setIsLoading(false);
        historyData = pushData;
      } else {
        setRecData([
          {
            response: recommendationData?.content,
            created_at: recommendationData?.created_at,
            id: recommendationData?.id,
          },
        ]);
        setIsLoading(false);
        // setUpdateSessionId(null);
        updatedSessionId.current = null;
        historyData = [
          {
            response: recommendationData?.content,
            created_at: recommendationData?.created_at,
            id: recommendationData?.id,
          },
        ];
      }
      setIsLoading(false);
      if (
        (previousScrollHeight && previousScrollHeight !== 0) ||
        (previousScrollTop && previousScrollTop !== 0)
      ) {
        setTimeout(() => {
          const container = document.getElementById("container");
          const newScrollHeight = container.scrollHeight;
          const scrollHeight = divRef.current.scrollHeight;
          const topTenPercent = scrollHeight * 0.03;
          container.scrollTop =
            previousScrollTop +
            (newScrollHeight - previousScrollHeight) -
            topTenPercent;
        }, 0);
      }
      return historyData;
    } catch (err) {
      // setLoadingHistory(false);
      setIsLoading(false);
      toast.error("Error:", err, {
        icon: <SioIcon name="close" size={16} color="blue" />,
      });
    }
    setIsLoading(false);
  };

  const perPage = 10;

  const handleScroll = useCallback(
    debounce((e) => {
      if (!divRef?.current) return;
      const currentScrollTop = divRef.current.scrollTop;
      const scrollHeight = divRef.current.scrollHeight;
      const topTenPercent = scrollHeight * 0.1;

      const container = document.getElementById("container");
      const previousScrollHeight = container.scrollHeight;
      const previousScrollTop = container.scrollTop;
      if (currentScrollTop <= topTenPercent) {
        setScrollToTop(true);
        setPage((prevPage) => {
          const updatedPage = prevPage + 1;
          if (updatedPage <= lastPage.current) {
            setRecData((prevArray) => {
              const updatedArray = [
                { LoadingData: "LoadingOldChat" },
                ...prevArray,
              ];
              return updatedArray;
            });

            fetchChatHistory(
              updatedPage,
              perPage,
              previousScrollHeight,
              previousScrollTop
            );
          }

          return updatedPage;
        });
      }
    }, 300),
    [page]
  );

  const [prompt, setPrompt] = useState(null);

  const inputRef = useRef(null);

  const [currentFileName, setCurrentFileName] = useState(null);
  const [loadingPromptResponse, setLoadingPromptResponse] = useState(false);

  const getLatestHistory = async () => {
    if (recId.current) {
      const { data } = await getUserNames();
      setUserId(data?.user?.id);
      const nextQueryParams = new URLSearchParams();
      nextQueryParams.append("page", 1);
      nextQueryParams.append("per_page", 10);
      nextQueryParams.append("recommendation_id", recId.current);
      setScrollToTop(false);
      const historyResponseData = await fetchChatHistory();
      if (historyResponseData[0]?.qualitative_saved === undefined) {
        const { qualitative_saved, quantitative_saved } =
          await getInitialChildSavedStatus(recId.current);
        setSavedAiChild([qualitative_saved, quantitative_saved]);
      } else {
        const historyResponseDataInput = historyResponseData[0];
        setSavedAiChild([
          historyResponseDataInput?.qualitative_saved,
          historyResponseDataInput?.quantitative_saved,
        ]);
      }
    }
  };

  useEffect(() => {
    const initialRender = async () => {
      setRecData([]);
      recId.current = recommendationData?.id;
      if (!recId.current) return;
      await getLatestHistory();
      setPage(1);

      if (
        APIData.status &&
        APIData?.payload?.apiSessionId === updatedSessionId.current
      ) {
        setRecData((prevArray) => {
          const updatedArray = [
            ...prevArray,
            { input: { content: APIData?.payload?.apiPrompt, id: null } },
            { response: "Loading..." },
          ];
          return updatedArray;
        });
      }
    };
    initialRender();
  }, [recommendationData]);

  useEffect(() => {
    const scrollToResponse = () => {
      if (divRef.current && !scrollToTop) {
        const container = divRef.current;
        const lastDiv = container.lastElementChild.lastElementChild;
        if (lastDiv) {
          lastDiv.scrollIntoView({ behavior: "smooth", block: "start" });
          const scrollPosition =
            lastDiv.offsetTop +
            lastDiv.offsetHeight -
            lastDiv.getBoundingClientRect().height -
            container.offsetHeight;

          container.scrollTo({
            top: scrollPosition + 90,
            behavior: "smooth",
          });
        }
      }
    };
    scrollToResponse();
  }, [recData]);

  useEffect(() => {
    if (recommendationData) {
      if (recommendationData.follow_up_questions) {
        setAiChildRecommendations([
          recommendationData.follow_up_questions.qualitative_question,
          recommendationData.follow_up_questions.quantitative_question,
        ]);
      } else {
        setAiChildRecommendations([
          recommendationData.qualitative_question,
          recommendationData.quantitative_question,
          recommendationData.qualitative_saved,
          recommendationData.quantitative_saved,
        ]);
      }
      setMessageId(recommendationData.id);

      let result;

      if (recommendationData?.is_combined) {
        if (!Array.isArray(recommendationData?.original_filename)) {
          let cleanedInput = recommendationData?.original_filename
            .replace(/^{|}$/g, "")
            .replace(/^"|"$/g, "");

          result = cleanedInput
            .split(/,(?=(?:[^"]*"[^"]*")*[^"]*$)/)
            .map((str) => str.trim().replace(/^"|"$/g, ""));
        } else {
          result = recommendationData?.original_filename;
        }
      } else {
        result = [recommendationData?.original_filename];
      }
      setCurrentFileName(result);
    }
  }, [recommendationData]);

  const handlePromptValue = (value) => {
    setDataFromChildren(value);
  };

  const clearPrompt = () => {
    document.getElementById("prompt_data").value = "";
  };

  const handleInputChange = useCallback(
    debounce((value) => {
      setPrompt(value);
    }, 500),
    []
  );

  const handlePrompt = (e) => {
    handleInputChange(e.target.value);
  };

  const fileUploadRequest = async () => {
    setLoadingPromptResponse(true);
    const promptText = document.getElementById("prompt_data").value;
    clearPrompt();

    try {
      setIsLoading(true);
      setRecData((prevArray) => {
        const updatedArray = [
          ...prevArray,
          { input: { content: promptText, id: null } },
          { response: "Loading..." },
        ];
        return updatedArray;
      });

      const token = await checkAndRefreshToken();
      if (!token) navigate("/login");

      const promptValue = promptText;

      if (!updatedSessionId.current) {
        const data = await startConversation(recId.current, token);
        // setUpdateSessionId(data.session_id);
        updatedSessionId.current = data.session_id;
        setAPIData((prevData) => ({
          ...prevData,
          status: true,
          payload: {
            apiPrompt: promptValue,
            apiSessionId: data.session_id,
          },
        }));
        const data2 = await addMessageToSession(
          data.session_id,
          promptValue,
          token,
          // currentFileName ? currentFileName : fileNamesList
          currentFileName
        );
        if (data2?.recommendation_id === recId.current) {
          setRecData((prevArray) => {
            const updatedArray = [...prevArray];
            updatedArray[updatedArray.length - 1] = {
              response: data2?.answer,
            };
            updatedArray[updatedArray.length - 2] = {
              input: { content: promptValue, id: data2?.message_id - 1 },
            };
            return updatedArray;
          });

          if (
            data2 &&
            data2?.qualitative_question !== null &&
            data2.quantitative_question !== null
          ) {
            const aiRec = [
              data2?.qualitative_question,
              data2?.quantitative_question,
              data2?.qualitative_saved,
              data2?.quantitative_saved,
            ];
            setAiChildRecommendations(aiRec);
          }
        }
        // setUpdateSessionId(data.session_id);
        updatedSessionId.current = data.session_id;
      } else {
        const sessionIdSnapshot = updatedSessionId.current;

        setAPIData((prevData) => ({
          ...prevData,
          status: true,
          payload: {
            apiPrompt: promptValue,
            apiSessionId: sessionIdSnapshot,
          },
        }));
        const data2 = await addMessageToSession(
          updatedSessionId.current,
          promptValue,
          token,
          // currentFileName ? currentFileName : fileNamesList
          currentFileName
        );

        setRecData((prevArray) => {
          const updatedArray = [...prevArray];
          updatedArray[updatedArray.length - 1] = {
            response: data2?.answer,
          };
          updatedArray[updatedArray.length - 2] = {
            input: { content: promptValue, id: data2?.message_id - 1 },
          };
          return updatedArray;
        });

        if (
          data2 &&
          data2?.qualitative_question !== null &&
          data2.quantitative_question !== null
        ) {
          const aiRec = [
            data2?.qualitative_question,
            data2?.quantitative_question,
            data2?.qualitative_saved,
            data2?.quantitative_saved,
          ];
          setAiChildRecommendations(aiRec);
        }
      }
      setLoadingPromptResponse(false);

      setPrompt("");
      setIsLoading(false);
      setAPIData((prevData) => ({
        ...prevData,
        status: false,
      }));
      setIsPromptResponseGenerated(!isPromptResponseGenerated);
    } catch (error) {
      setRecData((prevArray) => prevArray.slice(0, -2));
      toast.error("Error:", error, {
        icon: <SioIcon name="close" size={16} color="blue" />,
      });
      setAPIData((prevData) => ({
        ...prevData,
        status: false,
      }));
      return null;
    }
  };

  const handleKeyDown = async (e) => {
    if (e.key === "Enter" && !e.shiftKey && !isLoading) {
      console.log(e.target.value);
      const input = e.target.value;
      console.log({
        key: e.key,
        shiftKey: e.shiftKey,
        prompt,
        isPromptEmpty: !prompt || prompt.trim().length === 0,
      });
      e.preventDefault();
      if (!input || input.trim().length === 0) {
        toast.error("Please provide input", {
          icon: <SioIcon name="close" size={16} color="blue" />,
        });
        return;
      }
      setScrollToTop(false);
      await fileUploadRequest();
    } else if (e.key === "Enter" && e.shiftKey) {
      return;
    }
  };

  const getPromptResponse = async (value) => {
    try {
      setLoadingPromptResponse(true);
      const token = await checkAndRefreshToken();
      if (!token) navigate("/login");

      if (!updatedSessionId.current) {
        const data = await startConversation(recId.current, token);

        setAPIData((prevData) => ({
          ...prevData,
          status: true,
          payload: {
            apiPrompt: value,
            apiSessionId: data.session_id,
          },
        }));

        const data2 = await addMessageToSession(
          data.session_id,
          value,
          token,
          // currentFileName ? currentFileName : fileNamesList
          currentFileName
        );

        if (data2?.recommendation_id === recId.current) {
          setRecData((prevArray) => {
            const updatedArray = [...prevArray];
            updatedArray[updatedArray.length - 1] = {
              response: data2?.answer,
            };
            updatedArray[updatedArray.length - 2] = {
              input: { content: value, id: data2?.message_id - 1 },
            };

            return updatedArray;
          });

          if (
            data2 &&
            data2?.qualitative_question !== null &&
            data2.quantitative_question !== null
          ) {
            const aiRec = [
              data2?.qualitative_question,
              data2?.quantitative_question,
              data2?.qualitative_saved,
              data2?.quantitative_saved,
            ];
            setAiChildRecommendations(aiRec);
          }
        }
        // setUpdateSessionId(data.session_id);
        updatedSessionId.current = data.session_id;
      } else {
        const sessionIdSnapshot = updatedSessionId.current;

        setAPIData((prevData) => ({
          ...prevData,
          status: true,
          payload: {
            apiPrompt: value,
            apiSessionId: sessionIdSnapshot,
          },
        }));

        const data2 = await addMessageToSession(
          updatedSessionId.current,
          value,
          token,
          // currentFileName ? currentFileName : fileNamesList
          currentFileName
        );
        if (data2?.recommendation_id === recId.current) {
          setRecData((prevArray) => {
            const updatedArray = [...prevArray];
            updatedArray[updatedArray.length - 1] = {
              response: data2?.answer,
            };
            updatedArray[updatedArray.length - 2] = {
              input: { content: value, id: data2?.message_id - 1 },
            };

            return updatedArray;
          });
          if (
            data2 &&
            data2?.qualitative_question !== null &&
            data2.quantitative_question !== null
          ) {
            const aiRec = [
              data2?.qualitative_question,
              data2?.quantitative_question,
              data2?.qualitative_saved,
              data2?.quantitative_saved,
            ];
            setAiChildRecommendations(aiRec);
          }
          const date = new Date();
          const updatedData = { content: value };
          updatedData.created_at = date.toUTCString();
          updatedData.saved_status = false;
          handlePromptValue(updatedData);
        }
        setLoadingPromptResponse(false);

        setPrompt("");
        setIsLoading(false);
        setIsPromptResponseGenerated(!isPromptResponseGenerated);
      }
      setAPIData((prevData) => ({
        ...prevData,
        status: false,
      }));
    } catch (error) {
      console.error(error);
      setRecData((prevArray) => prevArray.slice(0, -2));
      toast.error("Error:", error, {
        icon: <SioIcon name="close" size={16} color="blue" />,
      });
      setAPIData((prevData) => ({
        ...prevData,
        status: false,
      }));
      return null;
    }
  };

  const handleFileName = () => {
    if (!Array.isArray(recommendationData?.original_filename)) {
      const cleanedInput = recommendationData?.original_filename
        .replace(/^{|}$/g, "")
        .replace(/^"|"$/g, "");

      return cleanedInput
        .split(/,(?=(?:[^"]*"[^"]*")*[^"]*$)/)
        .map((str) => str.trim().replace(/^"|"$/g, ""));
    } else {
      return recommendationData?.original_filename;
    }
  };

  return (
    <div className={`flex shrink grow  basis-0 w-[1%] flex-col`}>
      {isDataLoading ||
      !recommendationData ||
      !recData ||
      (recData && recData.length === 0) ? (
        <div
          className="flex flex-col justify-start items-center gap-10 text-md 
        font-semibold w-full h-full pt-20"
        >
          <svg width={0} height={0}>
            <defs>
              <linearGradient
                id="my_gradient"
                x1="0%"
                y1="0%"
                x2="0%"
                y2="100%"
              >
                <stop offset="0%" stopColor="#e01cd5" />
                <stop offset="100%" stopColor="#1CB5E0" />
              </linearGradient>
            </defs>
          </svg>
          <CircularProgress
            sx={{
              "svg circle": { stroke: "url(#my_gradient)" },
              size: "20px",
            }}
          />
        </div>
      ) : (
        <>
          <div className="flex justify-between items-md-center p-3 md:p-7 md:pb-3">
            <div className="flex gap-3 md:gap-4 items-md-center">
              <i className="sio-circle-icon bg-secondary/20 size-7 md:size-10 shrink-0">
                <SioIcon name="trend" size={24} />
              </i>
              <div>
                <h2 className="font-semibold text-base md:text-xl text-primary-dark mb-1">
                  {recommendationData?.category?.replace(
                    /[^a-zA-Z0-9 ]/g,
                    ""
                  ) || ""}
                </h2>
                <ul className="flex divide-x text-xs md:text-sm divide-dark leading-none flex-wrap">
                  <Tooltip title={handleFileName().join(",\n")}>
                    <li
                      className="px-2 ps-0 xs:my-1 sm:my-0 text-muted-dark hidden md:block 
                            w-[9rem] truncate"
                    >
                      {handleFileName()}
                    </li>
                  </Tooltip>
                  <li className="md:px-2 my-0 text-muted-dark">
                    Received on {calculateTime(recommendationData?.created_at)}
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex gap-1 md:gap-3">
              <Tooltip title="Share via Email">
                <Button
                  fab
                  outline
                  variant="dark"
                  onClick={() => handleShare()}
                >
                  <SioIcon name="share" size={16} />
                </Button>
              </Tooltip>
              <Tooltip title="Download Response">
                <Button
                  fab
                  outline
                  variant="dark"
                  onClick={() => {
                    const data = recData;
                    handleDownload(data);
                  }}
                >
                  <SioIcon name="download" size={16} />
                </Button>
              </Tooltip>
              <Tooltip title="Clear Conversations">
                <Button
                  fab
                  outline
                  variant="dark"
                  onClick={async () => {
                    handleRemoveModalClickOpen();
                  }}
                >
                  <SioIcon name="clear" size={18} />
                </Button>
              </Tooltip>
              <Dialog
                open={openRemoveModal}
                onClose={() => handleRemoveModalClose()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <div className="max-w-md flex justify-center items-center text-center p-8 flex-col">
                  <SioIcon name="delete" size={80} className="mb-5" />
                  <h1 className="text-primary-dark text-xl mb-7">
                    Are you sure you want to clear conversation?
                  </h1>
                  <div className="gap-4 flex">
                    <Button
                      variant="secondary"
                      onClick={async () => {
                        updatedSessionId.current = null;
                        const { message, data } = await deleteConversations(
                          recId.current
                        );
                        setRecData([
                          {
                            response: data?.content,
                            created_at: data?.created_at,
                            id: data?.id,
                          },
                        ]);
                        handleRemoveModalClose();
                        toast(message, {
                          position: "top-right",
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: false,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "dark",
                        });
                      }}
                      size={"sm"}
                    >
                      Yes, Remove
                    </Button>
                    <Button
                      size={"sm"}
                      onClick={() => handleRemoveModalClose()}
                      className="text-black"
                    >
                      Not Now
                    </Button>
                  </div>
                </div>
              </Dialog>
            </div>
          </div>
          <div
            id="container"
            ref={divRef}
            className={`overflow-y-scroll scrollbar-none p-3 md:p-7 pt-0`}
            onScroll={(e) => handleScroll(e)}
          >
            <div className={`flex flex-col`}>
              {
                <MarkdownComponent
                  markDownContainerData={recData}
                  loadingPromptResponse={loadingPromptResponse}
                  session_id={updatedSessionId}
                  filename={currentFileName}
                  setUpdatedRecData={setRecData}
                  recId={recId}
                />
              }
            </div>
          </div>
          <div className="flex flex-col rounded-t-lg shadow-inputField py-3 mt-auto">
            {aiChildRecommendations && aiChildRecommendations.length > 0 && (
              <div className="flex justify-around text-xs gap-2 md:gap-5 mx-2 md:mx-7 mb-2 md:mb-5">
                {!isLoading &&
                  aiChildRecommendations?.map(
                    (value, index) =>
                      index <= 1 && (
                        <div
                          key={index}
                          className="flex relative w-1/2 overflow-hidden"
                        >
                          <div className="h-7 w-7 p-1 absolute bg-darkGreen text-white rounded-tl-lg rounded-br-lg left-0">
                            <SioIcon name="suggestionPromt" size={20} />
                          </div>
                          <div
                            onClick={async () => {
                              setIsLoading(true);

                              setRecData((prevArray) => {
                                const updatedArray = [
                                  ...prevArray,
                                  { input: { content: value, id: null } },
                                  { response: "Loading..." },
                                ];
                                return updatedArray;
                              });

                              setScrollToTop(false);

                              await getPromptResponse(value);
                              setIsLoading(false);
                            }}
                            className="bg-secondary-200 rounded-lg p-1 ps-9 md:p-3 md:ps-9 hover:cursor-pointer flex items-start"
                          >
                            {/* <p className="line-clamp-2">{value}</p> */}
                            <SioTooltipClampedText text={value} lines={2} />
                            <button
                              className="ms-2"
                              onClick={async (event) => {
                                event.stopPropagation();
                                try {
                                  const data = await saveAiChildRecommendations(
                                    recData,
                                    messageId,
                                    index === 0
                                      ? "qualitative_question"
                                      : "quantitative_question"
                                  );
                                  data?.qualitative_saved
                                    ? setSavedAiChild([
                                        data?.qualitative_saved,
                                        data?.quantitative_saved,
                                      ])
                                    : setSavedAiChild([
                                        data?.qualitative_question,
                                        data?.quantitative_question,
                                      ]);
                                  toast(
                                    `Your question has been successfully.`,
                                    {
                                      icon: (
                                        <SioIcon
                                          name="check"
                                          size={16}
                                          color="blue"
                                        />
                                      ),
                                    }
                                  );
                                } catch (err) {
                                  toast.error("Filed to save the question", {
                                    icon: (
                                      <SioIcon
                                        name="close"
                                        size={16}
                                        color="blue"
                                      />
                                    ),
                                  });
                                }
                              }}
                            >
                              {(recData[recData.length - 1]
                                ?.qualitative_saved &&
                                index === 0) ||
                              (savedAiChild &&
                                savedAiChild[0] &&
                                index === 0) ||
                              (recData[recData.length - 1]?.qualitative_saved &&
                                index === 1) ||
                              (savedAiChild &&
                                savedAiChild[1] &&
                                index === 1) ? (
                                <SioIcon
                                  name="saved"
                                  className="-mr-[4px] -mt-0.5"
                                  size={18}
                                />
                              ) : (
                                <SioIcon
                                  name="save"
                                  className="-mr-[4px] -mt-0.5"
                                  size={18}
                                />
                              )}
                            </button>
                          </div>
                        </div>
                      )
                  )}
              </div>
            )}
            <div className="mx-2 md:mx-7">
              <div className={`sio-card-shadow flex items-center`}>
                <TextField
                  ref={inputRef}
                  multiline
                  minRows={1}
                  maxRows={8}
                  id="prompt_data"
                  className="w-[100%] focus:outline-none pl-2 text-sm h-[100%] scrollbar-none
                  3xl:text-[17px] 3xl:h-[100%]"
                  placeholder="Ask me Anything"
                  onChange={handlePrompt}
                  onKeyDown={handleKeyDown}
                  autoComplete="off"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "8px 8px 8px 16px",
                      "& fieldset": {
                        border: "none",
                      },
                    },
                  }}
                />
                <button
                  disabled={isLoading}
                  className={`soi-btn soi-btn-fab btn-primary shrink-0 my-2 me-2 self-end ${
                    isLoading ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  onClick={async () => {
                    if (isLoading) return;
                    if (!prompt || prompt.trim().length === 0) {
                      toast("Please provide valid input");
                      return;
                    }

                    setScrollToTop(false);
                    await fileUploadRequest();
                  }}
                >
                  <SioIcon name="submit" size={16} />
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SuggestionsContainer;
