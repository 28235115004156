/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import {
  getFilesByTopicsWise,
  getFilesList,
  getMappedRecommendations,
  getRecByS3Keys,
  getRecommendationById,
} from "../../../apis/recommendation/recommendation";
import Button from "../../../components/button";
import SuggestionsSidebar from "./suggestionsSidebar";
import SuggestionsContainer from "./suggestionsContainer";
import { useLocation, useNavigate } from "react-router-dom";
import { StrivedContext } from "../../../App";
import { DataContext } from "../../../components/layout/layout";
import SioIcon from "../../../components/icons/IconLibrary";

const Suggestions = () => {
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);
  const [filteredRec, setFilteredRec] = useState([]);
  const [isModileSidebarActive, setModileSidebarIsActive] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);

  const [fileUUID, setFileUUID] = useState([]);

  const location = useLocation();

  const params = new URLSearchParams(window.location.search);
  const pathId = Number(params.get("recommendationId"));

  const { recommendationId } = location.state || {};

  const navigate = useNavigate();

  const { setHighlightField, filesBasedSubTopics } = useContext(DataContext);

  const {
    suggestions,
    isGetStartedClicked,
    setIsGetStartedClicked,
    selectSubTopics,
    selectedTopics,
    s3Key,
    setSuggestions,
  } = useContext(StrivedContext);

  const getRecommendations = async (data) => {
    let mappedRecommendation = [];
    let recommendations = [];
    setSelectedSuggestion(null);
    setIsDataLoading(true);

    sessionStorage.setItem("subTopics", JSON.stringify(selectSubTopics));

    const subTopicsLength = JSON.parse(
      sessionStorage.getItem("subTopics")
    ).length;

    if (subTopicsLength > 0) {
      const modifiedData = selectSubTopics.flatMap((item) =>
        Object.values(item).flat()
      );

      const data = await getMappedRecommendations(modifiedData);

      data.forEach((item) => {
        if (item.roles.length > 0) {
          item.roles.forEach((role) => {
            if (role.recommendations) {
              mappedRecommendation.push(...role.recommendations);
            }
          });
        }
      });
      const s3KeysList = [
        ...new Set(
          mappedRecommendation.flatMap((rec) => rec.individual_s3_keys)
        ),
      ];
      sessionStorage.setItem("s3_keys", JSON.stringify(s3KeysList));
      if (mappedRecommendation.length === 0) {
        const files = await getFilesList();
        const filteredFileData = files.filter(
          (file) =>
            file.s3_key.includes(".csv") ||
            file.s3_key.includes(".json") ||
            file.s3_key.includes(".csv.filepart")
        );

        const s3_keys =
          JSON.parse(sessionStorage.getItem("s3_keys")).length > 0
            ? JSON.parse(sessionStorage.getItem("s3_keys"))
            : filteredFileData.map((file) => file.s3_key);

        setFileUUID([
          ...new Set(filteredFileData.map((file) => file?.file_uuid)),
        ]);
        const data1 = await getRecByS3Keys(
          s3_keys,
          filteredFileData,
          setSuggestions
        );

        let finalRecomendation = [];

        if (data1.length > 1) {
          finalRecomendation = data1.filter((rec) => rec?.is_combined);
          if (finalRecomendation.length === 0) finalRecomendation = data1;
        } else finalRecomendation = data1;

        if (recommendationId) {
          const exists = finalRecomendation?.some(
            (item) => item.id === recommendationId
          );
          if (exists) {
            recommendations = [...finalRecomendation];
          } else {
            params.append("recommendation_id", recommendationId);
            const historyRecommendation = await await getRecommendationById(
              params
            );
            if (historyRecommendation && historyRecommendation?.length > 0) {
              recommendations = [
                ...finalRecomendation,
                ...historyRecommendation,
              ];
            }
          }
        } else {
          recommendations = [...finalRecomendation];
        }
      } else {
        if (recommendationId) {
          const exists = mappedRecommendation?.some(
            (item) => item.id === recommendationId
          );
          if (exists) {
            recommendations = [...mappedRecommendation];
          } else {
            params.append("recommendation_id", recommendationId);
            const historyRecommendation = await await getRecommendationById(
              params
            );
            if (historyRecommendation && historyRecommendation?.length > 0) {
              recommendations = [
                ...mappedRecommendation,
                ...historyRecommendation,
              ];
            }
          }
        } else {
          recommendations = [...mappedRecommendation];
        }
      }
    } else {
      const files = await getFilesList();
      const filteredFileData = files.filter(
        (file) =>
          file.s3_key.includes(".csv") ||
          file.s3_key.includes(".json") ||
          file.s3_key.includes(".csv.filepart")
      );

      // const s3KeysList = filteredFileData.map((file) => file.s3_key);
      // sessionStorage.setItem("s3_keys", JSON.stringify(s3KeysList));

      const s3_keys =
        JSON.parse(sessionStorage.getItem("s3_keys")).length > 0
          ? JSON.parse(sessionStorage.getItem("s3_keys"))
          : filteredFileData.map((file) => file.s3_key);

      setFileUUID([
        ...new Set(filteredFileData.map((file) => file?.file_uuid)),
      ]);
      const data1 = await getRecByS3Keys(
        s3_keys,
        filteredFileData,
        setSuggestions
      );
      let finalRecomendation = [];

      if (data1.length > 1) {
        finalRecomendation = data1.filter((rec) => rec?.is_combined);
        if (finalRecomendation.length === 0) finalRecomendation = data1;
      } else {
        finalRecomendation = data1;
      }

      if (recommendationId) {
        const exists = finalRecomendation?.some(
          (item) => item.id === recommendationId
        );
        if (exists) {
          recommendations = [...finalRecomendation];
        } else {
          params.append("recommendation_id", recommendationId);
          const historyRecommendation = await await getRecommendationById(
            params
          );
          if (historyRecommendation && historyRecommendation?.length > 0) {
            recommendations = [...finalRecomendation, ...historyRecommendation];
          }
        }
      } else {
        recommendations = [...finalRecomendation];
      }
    }
    setIsDataLoading(false);

    setFilteredRec(
      recommendations.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      })
    );

    if (pathId || recommendationId) {
      setSelectedSuggestion(
        recommendations.find((item) => item.id === (recommendationId || pathId))
      );
    } else {
      setSelectedSuggestion(
        recommendations.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        })[0]
      );
    }
  };

  useEffect(() => {
    const isRefreshed = sessionStorage.getItem("isRefresh");
    if (isRefreshed === "true") {
      sessionStorage.removeItem("isRefresh");
      setHighlightField("Suggestions");
    }

    const handleBeforeUnload = () => {
      sessionStorage.setItem("isRefresh", "true");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    setHighlightField("Suggestions");
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    getRecommendations(suggestions);
  }, [sessionStorage.getItem("s3_keys")]);

  const handleModileSidebarToggle = () => {
    setModileSidebarIsActive(!isModileSidebarActive);
  };

  const handleAskMeAnything = () => {
    setHighlightField("Ask Anything");
    navigate("/");
  };

  const playGroundTopBar = () => {
    return (
      <div className="flex md:items-center shadow-md p-3 md:p-5 flex-col md:flex-row relative z-10">
        <div className="flex items-center">
          <Button
            outline
            fab
            variant="dark"
            className="me-2 size-8 flex lg:hidden"
            size={"xs"}
            onClick={handleModileSidebarToggle}>
            <SioIcon name="filter" size={24} />
          </Button>
          <h1 className="font-normal text-lg md:text-xl text-primary-dark">
            Suggestions
          </h1>
        </div>
        <p className="text-xs leading-tight md:text-sm text-secondary-dark md:border-l ml-7 md:ml-3 pl-3">
          You are viewing suggestions from Strived, choose a topic to view
          customized suggestions.
        </p>
        <div className="items-center ms-auto flex">
          <Button
            variant="secondary"
            className="hidden lg:flex"
            size={"sm"}
            onClick={() => {
              handleAskMeAnything();
            }}>
            Ask me anything
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="bg-white h-full rounded-lg flex justify-center">
      <div className={`h-full w-full flex flex-col overflow-hidden`}>
        {playGroundTopBar()}

        <div className={`flex flex-row overflow-hidden h-full`}>
          <SuggestionsSidebar
            isModileSidebarActive={isModileSidebarActive}
            recommendationData={filteredRec}
            setModileSidebarIsActive={setModileSidebarIsActive}
            setSelectedSuggestion={setSelectedSuggestion}
            recommendationId={recommendationId}
            selectedTopics={selectedTopics}
            selectSubTopics={selectSubTopics}
            isGetStartedClicked={isGetStartedClicked}
            setIsGetStartedClicked={setIsGetStartedClicked}
            isDataLoading={isDataLoading}
            setIsDataLoading={setIsDataLoading}
            filesBasedSubTopics={filesBasedSubTopics}
          />
          <SuggestionsContainer
            recommendationData={selectedSuggestion}
            setSelectedSuggestion={setSelectedSuggestion}
            // fileNamesList={fileNamesList}
            fileUUID={fileUUID}
            isDataLoading={isDataLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default Suggestions;
