import React, { useEffect, useState } from "react";
import { HotTable } from "@handsontable/react";

const CSVViewer = () => {
  const [parsedData, setParsedData] = useState([]);
  const [columnHeaders, setColumnHeaders] = useState([]);
  const [fileName, setFileName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [retryCount, setRetryCount] = useState(0);

  useEffect(() => {
    let messageInterval;
    let cleanup = false;

    const handleMessage = (event) => {
      if (event.origin !== window.location.origin) {
        return;
      }

      try {
        const { data, columns, fileName } = event.data;

        if (
          Array.isArray(data) &&
          Array.isArray(columns) &&
          typeof fileName === "string"
        ) {
          console.log("Received data successfully");
          if (!cleanup) {
            setParsedData(data);
            setColumnHeaders(columns);
            setFileName(fileName);
            setIsLoading(false);
          }

          // Clear the interval since we got the data
          if (messageInterval) {
            clearInterval(messageInterval);
          }

          // Acknowledge receipt
          if (window.opener && !window.opener.closed) {
            window.opener.postMessage(
              { type: "DATA_RECEIVED", success: true },
              window.location.origin
            );
          }
        }
      } catch (error) {
        console.error("Error processing received data:", error);
        if (!cleanup) {
          setIsLoading(false);
        }
      }
    };

    const requestData = () => {
      if (window.opener && !window.opener.closed) {
        console.log("Requesting data, attempt:", retryCount + 1);
        window.opener.postMessage(
          { type: "REQUEST_DATA" },
          window.location.origin
        );
        if (!cleanup) {
          setRetryCount((prev) => prev + 1);
        }
      } else {
        // Clear interval if opener is closed
        if (messageInterval) {
          clearInterval(messageInterval);
        }
      }
    };

    // Add event listener for postMessage
    window.addEventListener("message", handleMessage);

    // Start requesting data periodically
    messageInterval = setInterval(() => {
      if (retryCount < 10) {
        // Limit to 10 attempts
        requestData();
      } else {
        clearInterval(messageInterval);
        if (!cleanup) {
          setIsLoading(false);
        }
      }
    }, 1000);

    // Initial request
    requestData();

    return () => {
      cleanup = true;
      window.removeEventListener("message", handleMessage);
      if (messageInterval) {
        clearInterval(messageInterval);
      }
    };
  }, [retryCount]);

  if (isLoading && retryCount < 10) {
    return (
      <div className="bg-white h-full rounded-lg flex flex-col items-center">
        <div className="flex items-center justify-center h-screen">
          <p className="text-lg">
            Loading data... (Attempt {retryCount + 1}/10)
          </p>
        </div>
      </div>
    );
  }

  if (retryCount >= 10 && !parsedData.length) {
    return (
      <div className="bg-white h-full rounded-lg flex flex-col items-center">
        <div className="flex items-center justify-center h-screen">
          <p className="text-lg text-red-600 font-semibold">
            Failed to load data. Please try again.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white h-full rounded-lg flex flex-col items-center">
      {fileName && (
        <h2 className="text-xl font-bold mb-4 text-center">
          Viewing File: <span className="text-primary-800">{fileName}</span>
        </h2>
      )}

      {parsedData.length > 0 && columnHeaders.length > 0 ? (
        <HotTable
          data={parsedData}
          colHeaders={columnHeaders}
          rowHeaders={true}
          licenseKey="non-commercial-and-evaluation"
          stretchH="all"
          height="100%"
          width="100%"
          colWidths={() => {
            const numColumns = columnHeaders.length;
            const baseWidth = 100;
            const maxWidth = 150;
            const totalWidth = window.innerWidth;
            const calculatedWidth = Math.min(totalWidth / numColumns, maxWidth);
            return Math.max(calculatedWidth, baseWidth);
          }}
          className="handsontable"
          afterGetColHeader={(col, TH) => {
            if (!TH.classList.contains("custom-header")) {
              TH.classList.add("custom-header");
            }
          }}
          cells={(row, col, prop) => ({
            className: row === -1 ? "header-highlight" : "",
            readOnly: true,
          })}
        />
      ) : (
        <div className="flex items-center justify-center h-screen">
          <p className="text-lg">No data available</p>
        </div>
      )}
    </div>
  );
};

export default CSVViewer;
