// /* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import logoname from "../../assets/strived-logo-name.svg";
import { useNavigate } from "react-router-dom";
import {
  bookmarkQuestions,
  getPromptMessages,
} from "../../apis/recommendation/recommendation";
import { toast } from "react-toastify";
import InputField from "../InputField";
import { FiSearch, FiCheck } from "react-icons/fi";
import { debounce } from "lodash";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Menu,
  MenuItem,
  ThemeProvider,
} from "@mui/material";
import { MdKeyboardArrowDown } from "react-icons/md";
import { checkAndRefreshToken, clearTokens } from "../../utils/authUtils";
import { lightTheme } from "../../theme";
import Cookies from "js-cookie";
import { URL_POSTFIX } from "../../constants/constant";
import { DataContext } from "../layout/layout";
import { getUserNames } from "../../utils";
import SioIcon from "../icons/IconLibrary";
const SubSidebar = ({
  isSidebarOpen,
  toggleSidebar,
  sidebarSubContent,
  selectedQuestions,
}) => {
  const navigate = useNavigate();

  const {
    updatedQuestionsData,
    setUpdatedQuestionsData,
    isPromptResponseGenerated,
  } = useContext(DataContext);

  const settings = [
    {
      name: "Admin Panel",
      icon: "admin",
    },
    {
      name: "Profile Settings",
      icon: "profile",
    },
    {
      name: "Logout",
      icon: "logout",
    },
  ];
  const Mobilesettings = [...settings];

  const [filteredPromptHistory, setFilteredPromptHistory] = useState([]);
  const [promptHistory, setPromptHistory] = useState([]);
  const [loadingHistory, setLoadingHistory] = useState(false);
  const [bookmarksList, setBookmarksList] = useState([]);
  const [page, setPage] = useState(1);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const divRef = useRef(null);

  const toastConfig = ({ className, themeType, iconName }) => {
    return {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: false,
      icon: iconName,
      className: className,
      theme: themeType,
    };
  };

  const [lastPage, setLastPage] = useState(null);

  const getHumanPromptsData = async () => {
    const nextQueryParams = new URLSearchParams();
    const getUUID = await getUserNames();
    const uuid = getUUID.data.org_uuid;
    nextQueryParams.append("page", page);
    nextQueryParams.append("per_page", 20);
    nextQueryParams.append("org_uuid", uuid);
    const { history, total_pages } = await getPromptMessages(nextQueryParams);
    setFilteredPromptHistory(history);
    const savedPrompts = history
      .filter((item) => item.saved_status)
      .map((prompt) => prompt.id);
    setBookmarksList((prevPrompt) => [...prevPrompt, ...savedPrompts]);
    setLastPage(total_pages);
    setPromptHistory(history);
    setUpdatedQuestionsData(null);
  };

  useEffect(() => {
    getHumanPromptsData();
  }, [updatedQuestionsData, isPromptResponseGenerated]);

  const userLoginDate = new Date();

  const formatDate = (dateStr) => {
    const today = new Date();
    const targetDate = new Date(dateStr);

    const isToday = today.toDateString() === targetDate.toDateString();
    const isYesterday =
      new Date(today.setDate(today.getDate() - 1)).toDateString() ===
      targetDate.toDateString();

    const isSameMonth =
      userLoginDate.getMonth() === targetDate.getMonth() &&
      userLoginDate.getFullYear() === targetDate.getFullYear();

    const isSameYear = userLoginDate.getFullYear() === targetDate.getFullYear();

    if (isToday) return "Today";
    if (isYesterday) return "Yesterday";
    if (isSameMonth) return "This Month";
    if (isSameYear) {
      return targetDate.toLocaleDateString("en-US", { month: "long" });
    }

    return targetDate.toLocaleDateString("en-US", {
      month: "long",
      year: "numeric",
    });
  };

  const groupByFormattedDate = (data) => {
    if (!data) return null;

    const sortedData = data.sort((a, b) => {
      return new Date(b.created_at) - new Date(a.created_at);
    });
    return sortedData.reduce((acc, item) => {
      const dateKey = formatDate(item.created_at);

      const existingGroup = acc.find((group) => group.date === dateKey);
      if (existingGroup) {
        existingGroup.content.push(item);
      } else {
        acc.push({ date: dateKey, content: [item] });
      }

      return acc;
    }, []);
  };

  const handlePromptHistorySearch = debounce((value) => {
    setFilteredPromptHistory(() => {
      const data = promptHistory.filter((item) =>
        item.content.toLowerCase().includes(value.toLowerCase())
      );
      return data;
    });
  }, 500);

  const handlePromptInputChange = (event) => {
    const value = event.target.value;
    handlePromptHistorySearch(value);
  };

  const fetchChatHistory = async (page = 1, perPage = 20) => {
    if (page <= 0) return;
    const token = await checkAndRefreshToken();
    if (!token) navigate("/login");
    const nextQueryParams = new URLSearchParams();
    const getUUID = await getUserNames();
    const uuid = getUUID.data.org_uuid;
    nextQueryParams.append("page", page);
    nextQueryParams.append("per_page", perPage);
    nextQueryParams.append("org_uuid", uuid);
    try {
      setLoadingHistory(true);

      setTimeout(async () => {
        const { history } = await getPromptMessages(nextQueryParams);
        setLoadingHistory(false);
        const savedPrompts = history
          .filter((item) => item.saved_status)
          .map((prompt) => prompt.id);
        setBookmarksList((prevPrompt) => [...prevPrompt, ...savedPrompts]);
        setPromptHistory((prevPrompts) => [...prevPrompts, ...history]);
        setFilteredPromptHistory((prevPrompts) => [...prevPrompts, ...history]);
      }, 3000);
    } catch (err) {
      setLoadingHistory(false);
      toast.error("Error:", err.message, {
        icon: <SioIcon name="close" size={16} color="blue" />,
      });
      return null;
    }
  };

  const handleScroll = useCallback(
    debounce(() => {
      const currentScrollTop = divRef.current.scrollTop;
      const scrollHeight = divRef.current.scrollHeight;
      const clientHeight = divRef.current.clientHeight;
      const bottomTenPercent = scrollHeight - clientHeight * 0.2;

      if (currentScrollTop + clientHeight >= bottomTenPercent) {
        setPage((prevPage) => {
          const updatedPage = prevPage + 1;
          if (page < lastPage) fetchChatHistory(updatedPage);
          return updatedPage;
        });
      }
    }, 300),
    [page]
  );

  const handleBookmarks = (prompt) => {
    if (!bookmarksList.includes(prompt.id)) {
      setBookmarksList((prev) => [...prev, prompt.id]);
    } else {
      const index = bookmarksList.indexOf(prompt.id);
      if (index > -1) {
        setBookmarksList((prev) => [
          ...prev.slice(0, index),
          ...prev.slice(index + 1),
        ]);
      }
    }
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleAdminDashboard = async () => {
    const token = await checkAndRefreshToken();
    const refresh_Token = Cookies.get("refresh_token");
    if (token) {
      Cookies.set("refresh_token", refresh_Token, {
        domain: "trypadlock.com",
        path: "/",
        expires: 7,
      });
      Cookies.set("token", token, {
        domain: "trypadlock.com",
        path: "/",
        expires: 7,
      });
      const newUrl = `strived.trypadlock.com?token=${token}&refresh_token=${refresh_Token}`;
      window.open(`https://${newUrl}`, "_blank");
    } else {
      navigate("/login");
    }
  };

  const historyContainer = () => {
    return (
      <div
        className="mb-auto overflow-y-auto scrollbar-none"
        ref={divRef}
        id="container"
        onScroll={handleScroll}
      >
        {groupByFormattedDate(
          filteredPromptHistory.filter(
            (item, index, self) =>
              index === self.findIndex((obj) => obj.content === item.content)
          )
        ).map((values, ind) => {
          return (
            <Accordion
              disableGutters
              defaultExpanded
              sx={{}}
              className="w-full sio-accordion"
              key={ind}
            >
              <AccordionSummary
                expandIcon={
                  <MdKeyboardArrowDown className="text-muted-light text-2xl" />
                }
                aria-controls="panel1-content"
                id="panel1-header"
                className="sio-accordion-header"
              >
                <div className="flex items-center grow shrink-0">
                  <p className="font-montserrat text-[10px] text-muted-light font-bold uppercase">
                    {values.date}
                  </p>
                  <div className="h-[1px] bg-white/10 grow mx-2"></div>
                </div>
              </AccordionSummary>
              <AccordionDetails
                className="sio-accordion-body"
                sx={{ "& .Mui-expanded": { marginBottom: 0 } }}
              >
                <div ref={divRef}>
                  {values?.content?.length > 0 ? (
                    <ul className="sio-list-disc sio-list-dark sio-nav-list gap-1 mt-2 mb-0 ml-4">
                      {values?.content?.map((value, ind, arr) => {
                        if (!value?.type) return null;
                        return value?.content === "LoadingOldChat" ? (
                          <div className="text-center">
                            <CircularProgress size={24} />
                          </div>
                        ) : (
                          <li
                            key={ind}
                            className="group/item hover:bg-white/10 !px-2 !py-[6px] hover:!text-primary-light hover:rounded-lg before:!top-[10px] before:!-left-3"
                          >
                            <div
                              className="line-clamp-2 cursor-pointer"
                              key={ind}
                              onClick={() => {
                                navigate("/suggestions", {
                                  state: {
                                    recommendationId: value?.recommendation_id,
                                  },
                                });
                              }}
                            >
                              {value?.content}
                            </div>
                            <div
                              className={`${
                                bookmarksList.includes(value.id) ||
                                value.saved_status
                                  ? "sio-bookmarked"
                                  : ""
                              } from-50% z-10 rounded-lg absolute flex items-center right-0 top-0 pl-4 bottom-0 m-auto invisible group-hover/item:visible group-hover/item:bg-gradient-to-l group-hover/item:from-[#303030]`}
                            >
                              <button
                                className="me-1"
                                onClick={async () => {
                                  try {
                                    const { saved_status } =
                                      await bookmarkQuestions(value.id);
                                    handleBookmarks(value);
                                    selectedQuestions(
                                      `${value.id}${saved_status}`
                                    );
                                    toast(
                                      `Your question has been ${
                                        saved_status ? "saved" : "unsaved"
                                      } successfully.`,
                                      toastConfig({
                                        strokeType: "#00CC8F",
                                        className: "custom-toast",
                                        iconName: (
                                          <FiCheck
                                            style={{
                                              stroke: "#00CC8F",
                                            }}
                                          />
                                        ),
                                      })
                                    );
                                  } catch (err) {
                                    toast.error("Filed to save the question", {
                                      icon: (
                                        <SioIcon
                                          name="close"
                                          size={16}
                                          color="blue"
                                        />
                                      ),
                                    });
                                  }
                                }}
                              >
                                {bookmarksList.includes(value.id) ? (
                                  <SioIcon name="saved" size={20} />
                                ) : (
                                  <SioIcon name="save" size={20} />
                                )}
                              </button>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  ) : (
                    <div className="my-6 flex flex-col items-center">
                      <SioIcon
                        name="history"
                        className="fill-white/10"
                        size="80"
                      />
                      <p className="text-xs font-light text-muted34-light mt-3">
                        No Prompt history
                      </p>
                    </div>
                  )}
                </div>
              </AccordionDetails>
            </Accordion>
          );
        })}
        {loadingHistory && (
          <p className="text-center pb-5">
            <CircularProgress size={24} sx={{ color: "GrayText" }} />
          </p>
        )}
      </div>
    );
  };

  const dataSourceContainer = () => {
    return (
      <li>
        <h2 className="font-light text-primary-light text-lg mb-4">
          Data Source
        </h2>
        <ul className="sio-list-disc sio-list-dark sio-nav-list mt-2 mb-0">
          <li>
            <button
              onClick={() => {
                navigate("/customRecommendation");
              }}
            >
              Custom Recommendation
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                navigate("/childRecommendation");
              }}
            >
              Child Recommendation
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                navigate("/fileType");
              }}
            >
              File Type
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                navigate("/fileUpload");
              }}
            >
              File Upload
            </button>
          </li>
        </ul>
      </li>
    );
  };

  return (
    <div
      className={`sio-sidebar transition-width ${
        isSidebarOpen ? "sio-sidebar-open" : ""
      }`}
    >
      <div className="flex flex-col h-screen sio-sidebar-container">
        {isSidebarOpen && (
          <div className="sio-sidebar-brand">
            <img
              className={`${isSidebarOpen ? "" : ""} hover:cursor-pointer`}
              src={logoname}
              alt="strived.io-logo"
              loading="lazy"
              onClick={() => {
                navigate("/");
              }}
            />
            <button
              onClick={() => toggleSidebar()}
              className="-rotate-90 md:rotate-0"
            >
              <SioIcon
                name="leftArrowSquare"
                className="opacity-55"
                size="22"
              />
            </button>
          </div>
        )}

        <div className="sio-sidebar-body">
          <ul className="space-y-4 h-full">
            {sidebarSubContent === "Ask Anything" && isSidebarOpen && (
              <li className="flex h-full flex-col">
                <h2 className="font-light text-primary-light text-lg mb-4">
                  Question History
                </h2>
                <div className="mb-4">
                  <InputField
                    placeholder="Search..."
                    theme="dark"
                    css="flex-grow px-3 py-2 text-sm !bg-transparent focus:outline-none focus:border-none"
                    icon={<FiSearch />}
                    onChange={handlePromptInputChange}
                  />
                </div>
                {historyContainer()}
              </li>
            )}

            {sidebarSubContent === "DataSource" &&
              isSidebarOpen &&
              dataSourceContainer()}
          </ul>
        </div>

        {isSidebarOpen && (
          <ThemeProvider theme={lightTheme}>
            <Menu
              sx={{ mt: "45px", top: -64 }}
              id="menu-appbar"
              className=""
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              // transformOrigin={{
              //   vertical: "bottom",
              //   horizontal: "right",
              // }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {Mobilesettings.map((setting) => (
                <MenuItem
                  key={setting.name}
                  onClick={() => {
                    handleCloseUserMenu();
                    if (setting.name === "Admin Panel") {
                      handleAdminDashboard();
                    }
                    if (setting.name === "Logout") {
                      clearTokens();
                      Cookies.remove("token", { domain: URL_POSTFIX });
                      Cookies.remove("refresh_token", {
                        domain: URL_POSTFIX,
                      });
                      Cookies.remove("user_email", { domain: URL_POSTFIX });
                      Cookies.remove("org", { domain: URL_POSTFIX });
                      Cookies.remove("org_uuid", { domain: URL_POSTFIX });
                      Cookies.remove("user", { domain: URL_POSTFIX });
                      Cookies.remove("userName", { domain: URL_POSTFIX });
                      Cookies.remove("user_role", { domain: URL_POSTFIX });
                      navigate("/login");
                    }
                  }}
                >
                  <i className="soi-icon">
                    <SioIcon name={setting.icon} size={20} />
                  </i>
                  {setting.name}
                </MenuItem>
              ))}
            </Menu>
          </ThemeProvider>
        )}
      </div>
    </div>
  );
};

export default SubSidebar;
