/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import SavedQuestionsSidebar from "./savedQuestionsSidebar";
import SavedQuestionsContainer from "./savedQuestionsContainer";
import Button from "../../../components/button";
import { useNavigate } from "react-router-dom";
import {
  addMessageToSession,
  bookmarkQuestions,
  getSavedQuestions,
} from "../../../apis/recommendation/recommendation";
import { DataContext } from "../../../components/layout/layout";
import { CircularProgress, Drawer, TextField } from "@mui/material";
import { devApis } from "../../../constants/constant";
import Cookies from "js-cookie";
import { checkAndRefreshToken } from "../../../utils/authUtils";
import { debounce } from "lodash";
import { toast } from "react-toastify";
import SioIcon from "../../../components/icons/IconLibrary";

const SavedQuestions = () => {
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);
  const [filteredRec, setFilteredRec] = useState(null);
  const [isModileSidebarActive, setModileSidebarIsActive] = useState(false);
  const [isPromptEdited, setIsPromptEdited] = useState(false);
  const [editedPromptData, setEditedPromptData] = useState(null);
  const [savedQuestionsData, setSavedQuestionsData] = useState(null);
  const [addingNewQuestion, setAddingNewQuestion] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const params = new URLSearchParams(window.location.search);
  const pathId = Number(params.get("messageId"));

  const {
    setHighlightField,
    filesListData,
    updatedQuestionsData,
    setUpdatedQuestionsData,
  } = useContext(DataContext);

  const navigate = useNavigate();

  const savedQuestionsAPIFunction = async () => {
    const { saved_messages } = await getSavedQuestions();
    setFilteredRec(saved_messages);
    setSelectedSuggestion(saved_messages[0]);
    if (pathId === 0 && !pathId) {
      const newUrl = `${window.location.pathname}?messageId=${saved_messages[0]?.id}`;
      window.history.pushState({ path: newUrl }, "", newUrl);
    }
    setUpdatedQuestionsData(null);
  };

  useEffect(() => {
    savedQuestionsAPIFunction();

    const isRefreshed = sessionStorage.getItem("isRefresh");
    if (isRefreshed === "true") {
      sessionStorage.removeItem("isRefresh");
      setHighlightField("History");
    }

    const handleBeforeUnload = () => {
      sessionStorage.setItem("isRefresh", "true");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    setHighlightField("History");
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [updatedQuestionsData]);

  const handleModileSidebarToggle = () => {
    setModileSidebarIsActive(!isModileSidebarActive);
  };

  const handleAskMeAnything = () => {
    setHighlightField("Ask Anything");
    navigate("/");
  };

  const playGroundTopBar = () => {
    return (
      <div className="flex items-center shadow-md p-3 md:p-5 relative z-10">
        <Button
          outline
          fab
          variant="dark"
          className="me-2 size-8 flex lg:hidden"
          size={"xs"}
          onClick={handleModileSidebarToggle}
        >
          <SioIcon name="filter" size={18} />
        </Button>
        <h1 className="font-normal text-lg md:text-xl text-primary-dark">
          Saved Questions
        </h1>
        <div className="items-center ms-auto flex gap-5">
          <Button
            variant="default"
            className="hidden lg:flex"
            size={"sm"}
            onClick={toggleAddNewQuationDrawer(true)}
          >
            Add New
          </Button>
          <Button
            variant="secondary"
            className="hidden lg:flex"
            size={"sm"}
            onClick={() => {
              handleAskMeAnything();
            }}
          >
            Ask me anything
          </Button>
        </div>
      </div>
    );
  };

  const [openAddNewQuationDrawer, setAddNewQuationDrawerOpen] =
    React.useState(false);
  const toggleAddNewQuationDrawer = (newOpen) => () => {
    setAddNewQuationDrawerOpen(newOpen);
  };

  const [customPrompt, setCustomPrompt] = useState("");

  const handleInput = (event) => {
    const { value } = event.target;
    setCustomPrompt(value);
  };

  const debouncedInputHandler = debounce(handleInput, 500);

  return (
    <div className="bg-white h-full rounded-lg flex justify-center">
      {filteredRec && filteredRec.length === 0 ? (
        <div className="flex flex-col items-center justify-center gap-3 h-full">
          <i className="pb-5">
            <svg
              width="80"
              height="80"
              viewBox="0 0 80 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle opacity="0.1" cx="40" cy="40" r="40" fill="#111111" />
              <path
                opacity="0.54"
                d="M31.904 24C29.1251 24 27 26.2354 27 28.9499V52.5815C27 53.8589 27.8173 55.1362 28.9616 55.6153C29.452 55.7749 29.7789 55.9346 30.2693 55.9346C31.0867 55.9346 31.7405 55.6153 32.3944 55.1362L39.9138 48.2703L47.5968 55.1362C48.5776 55.9346 49.8853 56.254 51.0296 55.7749C52.1738 55.2959 52.9912 54.0185 52.9912 52.7411V28.9499C53.1546 26.2354 51.0296 24 48.2506 24H31.904ZM49.8853 28.9499V52.7411L42.2024 45.8752C41.5485 45.3962 40.7312 45.0768 40.0773 45.0768C39.26 45.0768 38.6061 45.3962 37.9523 45.8752L30.2693 52.5815V28.9499C30.2693 27.9918 30.9232 27.1935 31.904 27.1935H48.2506C49.2314 27.1935 49.8853 27.9918 49.8853 28.9499Z"
                fill="#111111"
                fill-opacity="0.74"
              />
            </svg>
          </i>
          <h1 className="text-primary-dark text-3xl font-semibold">
            You have no saved questions yet!
          </h1>
          <div className="flex items-center p-3 md:p-5 relative z-10">
            <Button
              outline
              fab
              variant="dark"
              className="me-2 size-8 flex lg:hidden"
              size={"xs"}
              onClick={handleModileSidebarToggle}
            >
              <SioIcon name="filter" size={18} />
            </Button>

            <div className="items-center ms-auto flex gap-5">
              <Button
                variant="default"
                className="hidden lg:flex"
                size={"sm"}
                onClick={toggleAddNewQuationDrawer(true)}
              >
                Add New
              </Button>
              <Button
                variant="secondary"
                className="hidden lg:flex"
                size={"sm"}
                onClick={() => {
                  handleAskMeAnything();
                }}
              >
                Ask me anything
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className={`h-full w-full flex flex-col overflow-hidden`}>
            {playGroundTopBar()}
            <div className={`flex flex-row overflow-hidden h-full`}>
              <SavedQuestionsSidebar
                recommendationData={filteredRec}
                setSelectedSuggestion={setSelectedSuggestion}
                setIsPromptEdited={setIsPromptEdited}
                selectedSuggestion={selectedSuggestion}
                setEditedPromptData={setEditedPromptData}
                setSavedQuestionsData={setSavedQuestionsData}
                setModileSidebarIsActive={setModileSidebarIsActive}
                isModileSidebarActive={isModileSidebarActive}
                setSelectedIndex={setSelectedIndex}
                selectedIndex={selectedIndex}
              />
              <SavedQuestionsContainer
                recommendationData={selectedSuggestion}
                isPromptEdited={isPromptEdited}
                editedPromptData={editedPromptData}
                savedQuestionsData={savedQuestionsData}
                setSavedQuestionsData={setSavedQuestionsData}
                setSelectedIndex={setSelectedIndex}
                suggestionsData={filteredRec}
                selectedIndex={selectedIndex}
              />
            </div>
          </div>
        </>
      )}
      <Drawer
        anchor={"right"}
        open={openAddNewQuationDrawer}
        onClose={toggleAddNewQuationDrawer(false)}
      >
        <div className="sio-sidebar-right w-[300px] md:w-[500px]">
          <div className="sio-sidebar-header flex items-center">
            <h2>Add New Question</h2>
            <Button
              className="soi-btn-fab soi-btn-fab soi-btn-flat btn-lg ms-auto"
              onClick={toggleAddNewQuationDrawer(false)}
            >
              <SioIcon name="closeLight" />
            </Button>
          </div>

          {addingNewQuestion ? (
            <div
              className="flex flex-col justify-start items-center gap-10 text-md 
                    font-semibold w-full h-full pt-20"
            >
              <svg width={0} height={0}>
                <defs>
                  <linearGradient
                    id="my_gradient"
                    x1="0%"
                    y1="0%"
                    x2="0%"
                    y2="100%"
                  >
                    <stop offset="0%" stopColor="#e01cd5" />
                    <stop offset="100%" stopColor="#1CB5E0" />
                  </linearGradient>
                </defs>
              </svg>
              <CircularProgress
                sx={{
                  "svg circle": { stroke: "url(#my_gradient)" },
                  size: "20px",
                }}
              />
              <h3>Please wait while we adding the question.</h3>
            </div>
          ) : (
            <>
              <div className="sio-sidebar-body h-auto p-6 !py-7">
                <div className="grid grid-cols-1 gap-y-6">
                  <TextField
                    label="Question"
                    multiline
                    rows={4}
                    fullWidth
                    onChange={debouncedInputHandler}
                  />
                </div>
              </div>

              <div className="sio-sidebar-footer gap-4 flex pt-0">
                <Button
                  variant="secondary"
                  size={"sm"}
                  onClick={async () => {
                    if (customPrompt) {
                      setAddingNewQuestion(true);
                      const promptResponse = await fetch(
                        `${devApis.PYTHON_BASE_URL}/start_conversation`,
                        {
                          method: "POST",
                          headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${Cookies.get("token")}`,
                          },
                          body: JSON.stringify({
                            recommendation_id: 0,
                          }),
                        }
                      );

                      const data = await promptResponse.json();
                      const newSessionId = data.session_id;

                      const token = await checkAndRefreshToken();
                      const response = await addMessageToSession(
                        newSessionId,
                        customPrompt,
                        token,
                        filesListData
                      );

                      await bookmarkQuestions(response?.message_id);
                      savedQuestionsAPIFunction();
                      setAddingNewQuestion(false);
                      setAddNewQuationDrawerOpen(false);
                      setUpdatedQuestionsData(true);
                      toast.success("Question added successfully", {
                        icon: <SioIcon name="check" size={16} color="blue" />,
                      });
                    } else {
                      toast("Please provide the custom question", {
                        icon: <SioIcon name="close" size={16} color="blue" />,
                      });
                    }
                  }}
                >
                  {" "}
                  Save Question{" "}
                </Button>
                <Button
                  variant="default"
                  onClick={toggleAddNewQuationDrawer(false)}
                  size={"sm"}
                  className="text-black"
                >
                  {" "}
                  Cancel{" "}
                </Button>
              </div>
            </>
          )}
        </div>
      </Drawer>
    </div>
  );
};

export default SavedQuestions;
