/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";
import Button from "../../components/button";
import MarkdownComponent from "../../components/MarkdownComponent";
import {
  addMessageToSession,
  saveAiChildRecommendations,
  startConversation,
} from "../../apis/recommendation/recommendation";
import { toast } from "react-toastify";
import { checkAndRefreshToken } from "../../utils/authUtils";
import { useLocation, useNavigate } from "react-router-dom";
import { lightTheme } from "../../theme";
import { ThemeProvider } from "@emotion/react";
import { Menu, MenuItem, TextField, Tooltip } from "@mui/material";
import SioIcon from "../../components/icons/IconLibrary";
import SioTooltipClampedText from "../../components/TooltipClampedText";
import { DataContext } from "../../components/layout/layout";

const AskMeAnything = () => {
  const location = useLocation();
  const {
    sessionId,
    promptData,
    promptInput,
    fileName,
    aiChildRecommendation,
    message_id,
  } = location.state || {};

  const { setIsPromptResponseGenerated } = useContext(DataContext);

  const [aiChildRec, setAiChildRec] = useState(aiChildRecommendation);
  const [heading, setHeading] = useState(promptInput);
  const [isLoading, setIsLoading] = useState(false);
  const [recData, setRecData] = useState([]);
  const [scrollToTop, setScrollToTop] = useState(false);
  const [savedAiChild, setSavedAiChild] = useState(null);

  const divRef = useRef(null);
  const navigate = useNavigate();
  const [loadingPromptResponse, setLoadingPromptResponse] = useState(false);
  const [prompt, setPrompt] = useState(null);
  const inputRef = useRef(null);
  const [filenames, setFilenames] = useState(null);
  const updatedSessionId = useRef(sessionId);
  const recId = useRef(null);

  useEffect(() => {
    setRecData([
      {
        response: promptData,
        id: message_id,
      },
    ]);
    setHeading(promptInput);
    setFilenames(fileName);
  }, []);

  useEffect(() => {
    setSavedAiChild(null);
    const scrollToResponse = () => {
      if (divRef.current && !scrollToTop) {
        const container = divRef.current;
        const lastDiv = container.lastElementChild.lastElementChild;
        if (lastDiv) {
          lastDiv.scrollIntoView({ behavior: "smooth", block: "start" });
          const scrollPosition =
            lastDiv.offsetTop +
            lastDiv.offsetHeight -
            lastDiv.getBoundingClientRect().height -
            container.offsetHeight;

          container.scrollTo({
            top: scrollPosition + 90,
            behavior: "smooth",
          });
        }
      }
    };
    scrollToResponse();
  }, [recData]);

  const getPromptResponse = async (value) => {
    try {
      setLoadingPromptResponse(true);
      const token = await checkAndRefreshToken();
      if (!token) navigate("/login");

      if (!updatedSessionId) {
        const data = await startConversation(0, token);
        const data2 = await addMessageToSession(
          data.session_id,
          value,
          token,
          fileName
        );

        setRecData((prevArray) => {
          const updatedArray = [...prevArray];
          updatedArray[updatedArray.length - 1] = {
            response: data2?.answer,
            id: data2.message_id,
          };
          return updatedArray;
        });
        if (
          data2 &&
          data2?.qualitative_question !== null &&
          data2.quantitative_question !== null
        ) {
          const aiRec = [
            data2?.qualitative_question,
            data2?.quantitative_question,
            data2?.qualitative_saved,
            data2?.quantitative_saved,
          ];
          setAiChildRec(aiRec);
        }
        updatedSessionId.current = data.session_id;
      } else {
        const data2 = await addMessageToSession(
          updatedSessionId.current,
          value,
          token,
          filenames
        );

        setRecData((prevArray) => {
          const updatedArray = [...prevArray];
          updatedArray[updatedArray.length - 1] = {
            response: data2?.answer,
            id: data2.message_id,
          };
          return updatedArray;
        });
        if (
          data2 &&
          data2?.qualitative_question !== null &&
          data2.quantitative_question !== null
        ) {
          const aiRec = [
            data2?.qualitative_question,
            data2?.quantitative_question,
            data2?.qualitative_saved,
            data2?.quantitative_saved,
          ];
          setAiChildRec(aiRec);
        }
        const date = new Date();
        const updatedData = { content: value };
        updatedData.created_at = date.toUTCString();
        updatedData.saved_status = false;
        // handlePromptValue(updatedData);
      }
      setLoadingPromptResponse(false);

      setPrompt("");
      setIsLoading(false);
      setIsPromptResponseGenerated((prevState) => {
        return !prevState;
      });
    } catch (error) {
      console.error(error);
      setRecData((prevArray) => prevArray.slice(0, -2));
      toast.error("Error:", error, {
        icon: <SioIcon name="close" size={16} color="blue" />,
      });
      return null;
    }
  };

  const clearPrompt = () => {
    document.getElementById("prompt_data").value = "";
  };

  const fileUploadRequest = async () => {
    setLoadingPromptResponse(true);
    const promptText = document.getElementById("prompt_data").value;
    clearPrompt();

    try {
      setIsLoading(true);
      setRecData((prevArray) => {
        const updatedArray = [
          ...prevArray,
          { input: { content: promptText, id: null } },
          { response: "Loading..." },
        ];
        return updatedArray;
      });

      const token = await checkAndRefreshToken();
      if (!token) navigate("/login");

      const promptValue = promptText;

      if (!updatedSessionId.current) {
        const data = await startConversation(recId.current, token);
        // setUpdateSessionId(data.session_id);
        updatedSessionId.current = data.session_id;
        // setAPIData((prevData) => ({
        //   ...prevData,
        //   status: true,
        //   payload: {
        //     apiPrompt: promptValue,
        //     apiSessionId: data.session_id,
        //   },
        // }));
        const data2 = await addMessageToSession(
          data.session_id,
          promptValue,
          token,
          fileName
        );
        if (data2?.recommendation_id === recId.current) {
          setRecData((prevArray) => {
            const updatedArray = [...prevArray];
            updatedArray[updatedArray.length - 1] = {
              response: data2?.answer,
            };
            return updatedArray;
          });
          if (
            data2 &&
            data2?.qualitative_question !== null &&
            data2.quantitative_question !== null
          ) {
            const aiRec = [
              data2?.qualitative_question,
              data2?.quantitative_question,
              data2?.qualitative_saved,
              data2?.quantitative_saved,
            ];
            setAiChildRec(aiRec);
          }
        }
        // setUpdateSessionId(data.session_id);
        updatedSessionId.current = data.session_id;
      } else {
        // setAPIData((prevData) => ({
        //   ...prevData,
        //   status: true,
        //   payload: {
        //     apiPrompt: promptValue,
        //     apiSessionId: sessionIdSnapshot,
        //   },
        // }));
        const data2 = await addMessageToSession(
          updatedSessionId.current,
          promptValue,
          token,
          fileName
        );

        setRecData((prevArray) => {
          const updatedArray = [...prevArray];
          updatedArray[updatedArray.length - 1] = {
            response: data2?.answer,
          };
          return updatedArray;
        });
        if (
          data2 &&
          data2?.qualitative_question !== null &&
          data2.quantitative_question !== null
        ) {
          const aiRec = [
            data2?.qualitative_question,
            data2?.quantitative_question,
            data2?.qualitative_saved,
            data2?.quantitative_saved,
          ];
          setAiChildRec(aiRec);
        }
      }
      setLoadingPromptResponse(false);

      setPrompt("");
      setIsLoading(false);
      // setAPIData((prevData) => ({
      //   ...prevData,
      //   status: false,
      // }));
    } catch (error) {
      setRecData((prevArray) => prevArray.slice(0, -2));
      toast.error("Error:", error, {
        icon: <SioIcon name="close" size={16} color="blue" />,
      });
      // setAPIData((prevData) => ({
      //   ...prevData,
      //   status: false,
      // }));
      return null;
    }
  };

  const handleKeyDown = async (e) => {
    if (e.key === "Enter" && !e.shiftKey && !isLoading) {
      e.preventDefault();
      if (!prompt || prompt.trim().length === 0) {
        toast.error("Please provide input", {
          icon: <SioIcon name="close" size={16} color="blue" />,
        });
        return;
      }
      setScrollToTop(false);
      await fileUploadRequest();
    } else if (e.key === "Enter" && e.shiftKey) {
      return;
    }
  };

  const [anchorElUser, setAnchorElUser] = useState(null);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const settings = [
    {
      name: "Share",
      icon: "share",
    },
    {
      name: "Save",
      icon: "save",
    },
    {
      name: "Download",
      icon: "download",
    },
  ];
  const Mobilesettings = [...settings];

  return (
    <div className="bg-white h-full rounded-lg flex justify-center">
      <div className="max-w-4xl mx-auto w-full flex flex-col px-2 mb-2 md:mb-5 md:px-5 sio-recommendation-contener">
        <div className={`h-full flex flex-col overflow-hidden items-center`}>
          <div className="flex flex-row w-full overflow-hidden h-full">
            <div className={`flex shrink grow basis-0 flex-col w-full`}>
              <div className="flex justify-between items-start pb-4 pt-4 md:pt-9 gap-3 md:gap-4 border-b">
                <div className="flex gap-3 md:gap-4 items-center">
                  <i className="sio-circle-icon self-start bg-secondary/20 size-7 md:size-10 shrink-0">
                    <SioIcon name="quotation" size={18} />
                  </i>
                  <div>
                    <h2 className="font-semibold text-base md:text-xl text-primary-dark self-center">
                      {heading}
                    </h2>
                  </div>
                </div>
                <div className="gap-1 md:gap-3 hidden md:flex self-center">
                  <Tooltip title="Share via Email">
                    <Button
                      outline
                      fab
                      variant="dark"
                      className="size-10"
                      onClick={() => null}>
                      <SioIcon name="share" size={16} />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Download Response">
                    <Button
                      outline
                      fab
                      variant="dark"
                      className="size-10"
                      onClick={() => {
                        // const data = recData;
                        // handleDownload(data);
                      }}>
                      <SioIcon name="download" size={16} />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Save Response">
                    <Button
                      outline
                      fab
                      variant="dark"
                      className="size-10"
                      onClick={async () => {
                        // setUpdateSessionId(null);
                        // const { message } = await deleteConversations(recId.current);
                        // toast(message, {
                        //   position: "top-right",
                        //   autoClose: 5000,
                        //   hideProgressBar: false,
                        //   closeOnClick: false,
                        //   pauseOnHover: true,
                        //   draggable: true,
                        //   progress: undefined,
                        //   theme: "dark",
                        // });
                      }}>
                      <SioIcon name="save" size={16} />
                    </Button>
                  </Tooltip>
                </div>
                <div className="flex md:hidden">
                  <Button
                    outline
                    fab
                    variant="dark"
                    onClick={handleOpenUserMenu}>
                    <SioIcon name="more" size={16} />
                  </Button>
                  <ThemeProvider theme={lightTheme}>
                    <Menu
                      id="menu-appbar"
                      className=""
                      anchorEl={anchorElUser}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      keepMounted
                      sx={{
                        mt: 4,
                      }}
                      open={Boolean(anchorElUser)}
                      onClose={handleCloseUserMenu}>
                      {Mobilesettings.map((setting) => (
                        <MenuItem
                          key={setting.name}
                          onClick={() => {
                            handleCloseUserMenu();
                          }}>
                          <i className="soi-icon">
                            <SioIcon name={setting.icon} size={14} />
                          </i>
                          {setting.name}
                        </MenuItem>
                      ))}
                    </Menu>
                  </ThemeProvider>
                </div>
              </div>
              <div
                id="container"
                ref={divRef}
                className={`overflow-x-hidden overflow-y-scroll scrollbar-none py-3 md:py-5 `}>
                <div className={`flex flex-col `}>
                  {
                    <MarkdownComponent
                      markDownContainerData={recData}
                      loadingPromptResponse={loadingPromptResponse}
                      session_id={updatedSessionId.current}
                      filename={filenames}
                      setUpdatedRecData={setRecData}
                    />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="flex flex-col rounded-t-lg shadow-inputField pt-1 md:pt-3 mt-auto">
            {aiChildRec && aiChildRec.length > 0 && (
              <div className="flex justify-around text-xs gap-2 md:gap-5 mb-2 md:mb-5 ">
                {!isLoading &&
                  aiChildRec?.map(
                    (value, index) =>
                      index <= 1 && (
                        <div
                          key={index}
                          className="flex relative  w-1/2 overflow-hidden">
                          <div className="h-7 w-7 p-1 absolute bg-darkGreen text-white rounded-tl-lg rounded-br-lg left-0">
                            <SioIcon name="suggestionPromt" size={20} />
                          </div>
                          <div
                            onClick={async () => {
                              setIsLoading(true);
                              setRecData((prevArray) => {
                                const updatedArray = [
                                  ...prevArray,
                                  {
                                    input: {
                                      content: value,
                                      id: value?.id,
                                    },
                                  },
                                  { response: "Loading..." },
                                ];
                                return updatedArray;
                              });
                              setScrollToTop(false);
                              await getPromptResponse(value);
                              setIsLoading(false);
                            }}
                            className="bg-secondary-200 rounded-lg p-1 ps-9 md:p-3 md:ps-9 hover:cursor-pointer flex items-start">
                            <SioTooltipClampedText text={value} lines={2} />
                            <button
                              className="ms-2"
                              onClick={async (event) => {
                                event.stopPropagation();
                                try {
                                  const {
                                    quantitative_saved,
                                    qualitative_saved,
                                  } = await saveAiChildRecommendations(
                                    message_id,
                                    index === 0
                                      ? "qualitative_question"
                                      : "quantitative_question"
                                  );
                                  setSavedAiChild([
                                    qualitative_saved,
                                    quantitative_saved,
                                  ]);
                                  toast(
                                    `Your question has been successfully.`,
                                    {
                                      icon: (
                                        <SioIcon
                                          name="check"
                                          size={16}
                                          color="blue"
                                        />
                                      ),
                                    }
                                  );
                                } catch (err) {
                                  toast.error("Filed to save the question", {
                                    icon: (
                                      <SioIcon
                                        name="close"
                                        size={16}
                                        color="blue"
                                      />
                                    ),
                                  });
                                }
                              }}>
                              {(recData[recData.length - 1]
                                ?.qualitative_saved &&
                                index === 0) ||
                              (savedAiChild &&
                                savedAiChild[0] &&
                                index === 0) ||
                              (recData[recData.length - 1]?.qualitative_saved &&
                                index === 1) ||
                              (savedAiChild &&
                                savedAiChild[1] &&
                                index === 1) ? (
                                <SioIcon
                                  name="saved"
                                  className="md:-mr-[4px] md:-mt-0.5"
                                  size={18}
                                />
                              ) : (
                                <SioIcon
                                  name="save"
                                  className="md:-mr-[4px] md:-mt-0.5"
                                  size={18}
                                />
                              )}
                            </button>
                          </div>
                        </div>
                      )
                  )}
              </div>
            )}
            <div className="">
              <div className={`sio-card-shadow flex items-center`}>
                <TextField
                  ref={inputRef}
                  onChange={(e) => {
                    setPrompt(e.target.value);
                  }}
                  id="prompt_data"
                  multiline
                  minRows={1}
                  maxRows={8}
                  className="w-[100%] focus:outline-none pl-2 text-sm h-[100%]
                  3xl:text-[17px] 3xl:h-[100%]"
                  placeholder="Ask me Anything"
                  onKeyDown={handleKeyDown}
                  autoComplete="off"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "8px 8px 8px 16px",
                      "& fieldset": {
                        border: "none",
                      },
                    },
                  }}
                />
                <button
                  disabled={isLoading} // Disable the button when isLoading is true
                  className={`soi-btn soi-btn-fab btn-primary shrink-0 my-2 me-2 self-end ${
                    isLoading ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  onClick={async () => {
                    if (isLoading) return; // Prevent execution when isLoading is true
                    if (!prompt || prompt.trim().length === 0) {
                      toast("Please provide valid input");
                      return;
                    }

                    setScrollToTop(false);
                    await fileUploadRequest();
                  }}>
                  <SioIcon name="submit" size={16} />
                </button>
              </div>
            </div>
          </div>
        </div>
        <Button
          className="soi-btn-fab soi-btn-fab soi-btn-flat btn-lg ms-auto absolute top-3 right-2 lg:right-7 lg:top-7 hidden md:block"
          onClick={() => {
            navigate("/");
          }}>
          <SioIcon name="closeLight" size={24} />
        </Button>
      </div>
    </div>
  );
};

export default AskMeAnything;
