/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Button from "../../../components/button";
import {
  addMessageToSession,
  deleteSavedQuestions,
  getSavedPromptResponse,
  saveAiChildRecommendations,
  startConversation,
} from "../../../apis/recommendation/recommendation";
import { toast } from "react-toastify";
import { debounce } from "lodash";
import { checkAndRefreshToken } from "../../../utils/authUtils";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../../../components/layout/layout";
import MarkdownComponent from "../../../components/MarkdownComponent";
import { CircularProgress, Dialog, TextField, Tooltip } from "@mui/material";
import SioIcon from "../../../components/icons/IconLibrary";
import SioTooltipClampedText from "../../../components/TooltipClampedText";

const SavedQuestionsContainer = ({
  recommendationData,
  isPromptEdited,
  editedPromptData,
  savedQuestionsData,
  setSavedQuestionsData,
  suggestionsData,
}) => {
  const { setUpdatedQuestionsData } = useContext(DataContext);
  const recId = useRef(recommendationData?.id);
  const [recData, setRecData] = useState(null);
  const divRef = useRef(null);
  const [updateSessionId, setUpdateSessionId] = useState(null);
  const [aiChildRecommendations, setAiChildRecommendations] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isQuestionResponseLoaading, setIsQuestionResponseLoaading] =
    useState(false);

  const navigate = useNavigate();

  const getSavedQuestionResponse = async (recId, recData) => {
    const token = await checkAndRefreshToken();
    if (!token) navigate("/login");
    const prompt = new URLSearchParams();
    setIsQuestionResponseLoaading(true);
    if (
      recData?.type.startsWith("recommendation") ||
      recData?.type === "qualitative_question" ||
      recData?.type === "quantitative__question"
    ) {
      // await bookmarkQuestions(value.id);
      await saveAiChildRecommendations(
        [recData],
        recData?.type === "qualitative_question" ||
          recData?.type === "quantitative__question"
          ? recData?.id
          : recommendationData?.recommendation_id,
        null,
        recData?.type
      );

      const { session_id } = await startConversation(
        recData?.type === "qualitative_question" ||
          recData?.type === "quantitative__question"
          ? recommendationData?.recommendation_id
          : recommendationData?.id,
        token
      );
      const data2 = await addMessageToSession(
        session_id,
        recData?.content,
        token,
        recommendationData.filename,
        "yes"
      );
      prompt.append("message_id", data2.message_id - 1);
    } else {
      prompt.append("message_id", recId);
    }

    const { answer } = await getSavedPromptResponse(prompt);
    setAiChildRecommendations([
      answer?.qualitative_question,
      answer?.quantitative_question,
    ]);

    const responseObject = {
      response: answer?.content,
      id: answer?.id,
      likes: answer?.likes,
      disLikes: answer?.dislikes,
      created_at: answer?.created_at,
    };

    setUpdateSessionId(answer?.session_id);
    setRecData([responseObject]);

    setIsQuestionResponseLoaading(false);
    // setCurrentFileName(answer?.filename);
  };

  useEffect(() => {
    if (!recommendationData) return;
    setRecData([]);
    setSavedQuestionsData(null);
    if (editedPromptData) {
      setAiChildRecommendations([
        editedPromptData?.qualitative_question,
        editedPromptData?.quantitative_question,
      ]);
      const responseObject = {
        response: editedPromptData?.answer,
        id: editedPromptData?.id,
        likes: editedPromptData?.likes,
        disLikes: editedPromptData?.dislikes,
        created_at: editedPromptData?.created_at,
      };
      setRecData([responseObject]);
      // setCurrentFileName(editedPromptData?.filename);
      setUpdateSessionId(editedPromptData?.new_session_id);
    } else {
      getSavedQuestionResponse(recommendationData?.id, recommendationData);
    }
  }, [recommendationData, editedPromptData]);

  const computeDateTime = (updated) => {
    let hours = updated.getHours();

    const minutes = updated.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;
    hours = hours ? hours : 12;

    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

    const time = `${hours}:${formattedMinutes} ${ampm}`;
    return time;
  };

  const calculateTime = (date) => {
    if (!date) return null;
    const today = new Date();
    const updated = new Date(date);
    const updatedMonth = updated.toLocaleString("default", { month: "short" });
    const updatedDate = updated.getDate();
    const todayMonth = today.toLocaleString("default", { month: "short" });
    const todayDate = today.getDate();
    let recommendationDate;
    if (todayMonth === updatedMonth) {
      if (todayDate - updatedDate >= 1) {
        recommendationDate = `${updatedMonth} ${updatedDate}, ${computeDateTime(
          updated
        )}`;
      } else if (todayDate - updatedDate === 0) {
        recommendationDate = computeDateTime(updated);
      }
    } else {
      recommendationDate = computeDateTime(updated);
    }
    return recommendationDate;
  };

  const [prompt, setPrompt] = useState(null);

  const { setDataFromChildren } = useContext(DataContext);
  const inputRef = useRef(null);

  const [loadingPromptResponse, setLoadingPromptResponse] = useState(false);

  const handlePromptValue = (value) => {
    setDataFromChildren(value);
  };

  const clearPrompt = () => {
    document.getElementById("prompt_data").value = "";
  };

  const handleInputChange = useCallback(
    debounce((value) => {
      setPrompt(value);
    }, 500),
    []
  );

  const handlePrompt = (e) => {
    handleInputChange(e.target.value);
  };

  const fileUploadRequest = async () => {
    setLoadingPromptResponse(true);
    const promptText = document.getElementById("prompt_data").value;
    clearPrompt();

    try {
      setIsLoading(true);
      setRecData((prevArray) => {
        const updatedArray = [
          ...prevArray,
          { input: promptText },
          { response: "Loading..." },
        ];
        return updatedArray;
      });

      const token = await checkAndRefreshToken();
      if (!token) navigate("/login");

      const promptValue = promptText;

      if (!updateSessionId) {
        const data = await startConversation(recId.current, token);
        setUpdateSessionId(data.session_id);

        const data2 = await addMessageToSession(
          data.session_id,
          promptValue,
          token,
          recommendationData.filename
        );

        setRecData((prevArray) => {
          const updatedArray = [...prevArray];
          updatedArray[updatedArray.length - 1] = {
            response: data2?.answer,
          };
          return updatedArray;
        });
        if (
          data2 &&
          data2?.qualitative_question !== null &&
          data2.quantitative_question !== null
        ) {
          const aiRec = [
            data2?.qualitative_question,
            data2?.quantitative_question,
            data2?.qualitative_saved,
            data2?.quantitative_saved,
          ];
          setAiChildRecommendations(aiRec);
        }
      } else {
        const data2 = await addMessageToSession(
          updateSessionId,
          promptValue,
          token,
          recommendationData.filename
        );
        setRecData((prevArray) => {
          const updatedArray = [...prevArray];
          updatedArray[updatedArray.length - 1] = {
            response: data2?.answer,
          };
          return updatedArray;
        });
        if (
          data2 &&
          data2?.qualitative_question !== null &&
          data2.quantitative_question !== null
        ) {
          const aiRec = [
            data2?.qualitative_question,
            data2?.quantitative_question,
            data2?.qualitative_saved,
            data2?.quantitative_saved,
          ];
          setAiChildRecommendations(aiRec);
        }
      }
      setLoadingPromptResponse(false);

      setPrompt("");
      setIsLoading(false);
    } catch (error) {
      setRecData((prevArray) => prevArray.slice(0, -2));
      toast.error("Error:", error, {
        icon: <SioIcon name="close" size={16} color="blue" />,
      });
      return null;
    }
  };

  const handleKeyDown = async (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();

      const input = e.target.value;
      if (!input || input.trim().length === 0) {
        toast.error("Please provide input", {
          icon: <SioIcon name="close" size={16} color="blue" />,
        });
        return;
      }
      await fileUploadRequest();
    } else if (e.key === "Enter" && e.shiftKey) {
      return;
    }
  };

  const getConversationsResponse = async (value) => {
    try {
      setLoadingPromptResponse(true);
      const token = await checkAndRefreshToken();
      if (!token) navigate("/login");

      if (!updateSessionId) {
        const data = await startConversation(recId.current, token);
        const data2 = await addMessageToSession(
          data.session_id,
          value,
          token,
          recommendationData.filename
        );

        setRecData((prevArray) => {
          const updatedArray = [...prevArray];
          updatedArray[updatedArray.length - 1] = {
            response: data2?.answer,
          };
          return updatedArray;
        });
        if (
          data2 &&
          data2?.qualitative_question !== null &&
          data2.quantitative_question !== null
        ) {
          const aiRec = [
            data2?.qualitative_question,
            data2?.quantitative_question,
            data2?.qualitative_saved,
            data2?.quantitative_saved,
          ];
          setAiChildRecommendations(aiRec);
        }
        setUpdateSessionId(data.session_id);
      } else {
        const data2 = await addMessageToSession(
          updateSessionId,
          value,
          token,
          recommendationData.filename
        );

        setRecData((prevArray) => {
          const updatedArray = [...prevArray];
          updatedArray[updatedArray.length - 1] = {
            response: data2?.answer,
          };
          return updatedArray;
        });
        if (
          data2 &&
          data2?.qualitative_question !== null &&
          data2.quantitative_question !== null
        ) {
          const aiRec = [
            data2?.qualitative_question,
            data2?.quantitative_question,
            data2?.qualitative_saved,
            data2?.quantitative_saved,
          ];
          setAiChildRecommendations(aiRec);
        }
        const date = new Date();
        const updatedData = { content: value };
        updatedData.created_at = date.toUTCString();
        updatedData.saved_status = false;
        handlePromptValue(updatedData);
      }
      setLoadingPromptResponse(false);

      setPrompt("");
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setRecData((prevArray) => prevArray.slice(0, -2));
      toast.error("Error:", error, {
        icon: <SioIcon name="close" size={16} color="blue" />,
      });
      return null;
    }
  };

  const [deletedQuestionContent, setDeletedQuestionContent] = useState(null);
  const [openRemoveModal, setRemoveModalOpen] = useState(false);

  const handleRemoveModalClickOpen = (item) => {
    setDeletedQuestionContent(item);
    setRemoveModalOpen(true);
  };

  const handleRemoveModalClose = () => {
    setRemoveModalOpen(false);
  };

  const handleRemoveQuestion = () => {
    try {
      deleteSavedQuestions(deletedQuestionContent);

      toast.success("Question has been deleted successfully", {
        icon: <SioIcon name="check" size={16} color="blue" />,
      });
      handleRemoveModalClose();
    } catch (err) {
      toast.error("Error:", err, {
        icon: <SioIcon name="close" size={16} color="blue" />,
      });
    }
  };

  return (
    <div className={`flex shrink grow  basis-0 w-[1%] flex-col`}>
      {isQuestionResponseLoaading ||
      isPromptEdited ||
      !recommendationData ||
      !recData ? (
        <div
          className="flex flex-col justify-start items-center gap-10 text-md 
          font-semibold w-full h-full pt-20"
        >
          <svg width={0} height={0}>
            <defs>
              <linearGradient
                id="my_gradient"
                x1="0%"
                y1="0%"
                x2="0%"
                y2="100%"
              >
                <stop offset="0%" stopColor="#e01cd5" />
                <stop offset="100%" stopColor="#1CB5E0" />
              </linearGradient>
            </defs>
          </svg>
          <CircularProgress
            sx={{
              "svg circle": { stroke: "url(#my_gradient)" },
              size: "20px",
            }}
          />
          {(isQuestionResponseLoaading || isPromptEdited) && (
            <h2>Please wait while we generate the response.</h2>
          )}
        </div>
      ) : (
        <>
          <div className="flex justify-between items-md-center p-3 md:p-7 md:pb-3">
            <div className="flex gap-3 md:gap-4 items-md-center">
              <i className="sio-circle-icon bg-warning/20 size-7 md:size-10 shrink-0">
                <SioIcon name="saved" className="size-4 md:size-6" />
              </i>
              <div>
                <h2 className="font-semibold text-base md:text-xl text-primary-dark mb-1">
                  {savedQuestionsData?.content || recommendationData?.content}
                </h2>
                <ul className="flex divide-x text-xs md:text-sm divide-dark leading-none flex-wrap">
                  <li className="my-0 text-muted-dark">
                    Saved on{" "}
                    {calculateTime(
                      recommendationData?.created_at ||
                        savedQuestionsData?.created_at
                    )}
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex gap-1 md:gap-3">
              <Tooltip title="Remove Question">
                <Button
                  outline
                  fab
                  variant="dark"
                  onClick={() => {
                    handleRemoveModalClickOpen(recommendationData?.id);
                  }}
                >
                  <SioIcon name="delete" size={16} />
                </Button>
              </Tooltip>
            </div>
          </div>
          <div
            id="container"
            ref={divRef}
            className={`overflow-y-scroll scrollbar-none p-3 md:p-7 pt-0`}
          >
            <div className={`flex flex-col`}>
              {
                <MarkdownComponent
                  markDownContainerData={recData}
                  loadingPromptResponse={loadingPromptResponse}
                  session_id={updateSessionId}
                  filename={recommendationData.filename}
                  setUpdatedRecData={setRecData}
                />
              }
            </div>
          </div>
          <div className="flex flex-col rounded-t-lg shadow-inputField py-3 mt-auto">
            {aiChildRecommendations && aiChildRecommendations.length > 0 && (
              <div className="flex justify-around text-xs gap-2 md:gap-5 mx-2 md:mx-7 mb-2 md:mb-5">
                {!isLoading &&
                  aiChildRecommendations?.map(
                    (value, index) =>
                      index <= 1 && (
                        <div
                          key={index}
                          className="flex relative w-1/2 overflow-hidden"
                        >
                          <div className="h-7 w-7 p-1 absolute bg-darkGreen text-white rounded-tl-lg rounded-br-lg left-0">
                            <SioIcon name="suggestionPromt" size={20} />
                          </div>
                          <div
                            onClick={async () => {
                              setIsLoading(true);
                              setRecData((prevArray) => {
                                const updatedArray = [
                                  ...prevArray,
                                  { input: value },
                                  { response: "Loading..." },
                                ];
                                return updatedArray;
                              });
                              await getConversationsResponse(value);
                              setIsLoading(false);
                            }}
                            className="bg-secondary-200 rounded-lg p-1 ps-9 md:p-3 md:ps-9 hover:cursor-pointer flex items-start"
                          >
                            {/* <p className="line-clamp-2">{value}</p> */}
                            <SioTooltipClampedText text={value} lines={2} />
                          </div>
                        </div>
                      )
                  )}
              </div>
            )}
            <div className="mx-2 md:mx-7">
              <div
                className={`sio-card-shadow flex items-center ${
                  isLoading ? "" : ""
                }`}
              >
                <TextField
                  ref={inputRef}
                  multiline
                  minRows={1}
                  maxRows={8}
                  id="prompt_data"
                  className="w-[100%] focus:outline-none pl-2 text-sm h-[100%]
                      3xl:text-[17px] 3xl:h-[100%]"
                  placeholder="Ask me Anything"
                  onChange={handlePrompt}
                  onKeyDown={handleKeyDown}
                  autoComplete="off"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "8px 8px 8px 16px",
                      "& fieldset": {
                        border: "none",
                      },
                    },
                  }}
                />
                <button
                  // disabled={`${isLoading ? true : false}`}
                  className="soi-btn soi-btn-fab btn-primary shrink-0 my-2 me-2 self-end"
                  onClick={async () => {
                    if (!prompt || prompt.trim().length === 0) {
                      toast("Please provide valid input");
                      return;
                    }

                    await fileUploadRequest();
                  }}
                >
                  <SioIcon name="submit" size={16} />
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      <Dialog
        open={openRemoveModal}
        onClose={() => handleRemoveModalClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="max-w-md flex justify-center items-center text-center p-8 flex-col">
          <SioIcon name="delete" size={80} className="mb-5" />
          <h1 className="text-primary-dark text-xl mb-7">
            Are you sure you want to remove saved question?
          </h1>
          <div className="gap-4 flex">
            <Button
              variant="secondary"
              onClick={() => {
                handleRemoveQuestion();
                setUpdatedQuestionsData(false);
              }}
              size={"sm"}
            >
              Yes, Remove
            </Button>
            <Button
              size={"sm"}
              onClick={() => handleRemoveModalClose()}
              className="text-black"
            >
              Not Now
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default SavedQuestionsContainer;
