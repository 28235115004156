import axios from "axios";
import { URL_POSTFIX } from "../constants/constant";
import { getNavigate } from "./index";

let isRefreshing = false;
let refreshSubscribers = [];
let cachedToken = null;
let cachedTokenExpiry = null;
const Cookies = require("js-cookie");
const onRefreshed = (token) => {
  refreshSubscribers.map((cb) => cb(token));
  refreshSubscribers = []; // Clear subscribers after use
};

const addRefreshSubscriber = (cb) => {
  refreshSubscribers.push(cb);
};

const isTokenExpired = (token) => {
  try {
    const payload = JSON.parse(atob(token.split(".")[1]));
    const expiry = payload?.exp;
    return expiry < Date.now() / 1000;
  } catch (error) {
    console.error("Error parsing token:", error);
    return true;
  }
};

export const checkAndRefreshToken = async () => {
  const token = Cookies.get("token");
  const refreshToken = Cookies.get("refresh_token");

  if (!token) {
    console.log("No token found.");
    const navigate = getNavigate();
    if (navigate) navigate("/login");
    return null;
  }

  // Return cached token if already refreshed
  if (
    cachedToken &&
    cachedTokenExpiry &&
    cachedTokenExpiry > Date.now() / 1000
  ) {
    return cachedToken;
  }

  if (isTokenExpired(token)) {
    console.log("Token is expired. Attempting to refresh...");

    if (refreshToken) {
      if (isRefreshing) {
        return new Promise((resolve) => {
          addRefreshSubscriber((newToken) => {
            resolve(newToken);
          });
        });
      }

      isRefreshing = true;

      try {
        const response = await axios.post(
          `https://api.trypadlock.com/padlockAdmin/refresh`,
          { refresh: refreshToken }
        );

        if (response.status === 200) {
          const newToken = response.data.token;
          const domainToSave = `${URL_POSTFIX}`;
          Cookies.set("token", newToken, {
            domain: domainToSave,
            expires: 7,
          });

          cachedToken = newToken;
          cachedTokenExpiry = JSON.parse(atob(newToken.split(".")[1]))?.exp;
          isRefreshing = false;
          onRefreshed(newToken);
          return newToken;
        } else {
          console.error("Failed to refresh token");
          isRefreshing = false;
          return null;
        }
      } catch (error) {
        console.error("Error refreshing token:", error);
        isRefreshing = false;
        return null;
      }
    } else {
      console.error("No refresh token available.");
      return null;
    }
  }

  return token;
};

export const clearTokens = () => {
  cachedToken = null;
  cachedTokenExpiry = null;

  isRefreshing = false;
  refreshSubscribers = [];
};
