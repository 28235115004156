/* eslint-disable no-unused-vars */
import React, { useState, useMemo, useContext, useEffect } from "react";
import signupLogo from "../../../assets/strived-logo-icon.svg";
import Button from "../../../components/button";

import { ThemeProvider, useTheme } from "@mui/material/styles";
import { lightTheme, darkTheme } from "../../../theme";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { StrivedContext } from "../../../App";
import { saveUserPreferences } from "../../../apis/recommendation/recommendation";
import SioIcon from "../../../components/icons/IconLibrary";

const SavedDataTopics = (props) => {
  const navigate = useNavigate();

  const {
    selectedTopics,
    setSelectedTopics,
    selectSubTopics,
    setSelectSubTopics,
    setIsGetStartedClicked,
    setUpdatedOnboardingData,
  } = useContext(StrivedContext);

  const onboardingData = [
    {
      topic: "Student Information System (SIS)",
      subTopics: [
        "Attendance",
        "Demographics",
        "Course Grades",
        "Discipline",
        "Enrollment",
        "Scheduling",
        "Parent/Guardian Info",
      ],
    },
    {
      topic: "Assessments",
      subTopics: [
        "Standardized Tests",
        "Benchmark Tests",
        "Formative Assessments",
        "Summative Assessments",
        "Diagnostic Assessments",
      ],
    },
    {
      topic: "Special Education",
      subTopics: [
        "IEP Goals",
        "Services Provided",
        "Accommodations",
        "Evaluations",
      ],
    },
    {
      topic: "Behavior Systems",
      subTopics: ["Referrals", "Incentives", "Incident Reports", "SEL Metrics"],
    },
    {
      topic: "Learning Management Systems (LMS)",
      subTopics: [
        "Assignments",
        "Course Content",
        "Participation",
        "Assessments",
      ],
    },
  ];

  const handleSelectedTopicData = (item) => {
    const index = onboardingData.findIndex((data) => data.topic === item);
    const topicSubTopics = onboardingData[index].subTopics;

    setSelectSubTopics((prevData) => {
      const existingEntryIndex = prevData.findIndex((entry) =>
        Object.keys(entry).includes(index.toString())
      );

      if (existingEntryIndex > -1) {
        return prevData.filter((_, i) => i !== existingEntryIndex);
      } else {
        return [...prevData, { [index]: topicSubTopics }];
      }
    });

    setSelectedTopics((prevTopics) => {
      if (prevTopics.includes(item)) {
        return prevTopics.filter((topic) => topic !== item);
      } else {
        return [...prevTopics, item];
      }
    });
  };

  // const handleSubTopicData = (clickedSubTopic, parentIndex) => {
  //   let data;
  //   console.log(clickedSubTopic, parentIndex);
  //   setSelectSubTopics((prevData) => {
  //     const parentKey = parentIndex.toString();

  //     const existingEntry = prevData.find((entry) =>
  //       Object.keys(entry).includes(parentKey)
  //     );

  //     if (existingEntry) {
  //       const currentSubTopics = existingEntry[parentKey];

  //       if (currentSubTopics.includes(clickedSubTopic)) {
  //         const updatedSubTopics = currentSubTopics.filter(
  //           (subTopic) => subTopic !== clickedSubTopic
  //         );

  //         if (updatedSubTopics.length === 0) {
  //           data = prevData.filter(
  //             (entry) => !Object.keys(entry).includes(parentKey)
  //           );
  //         } else {
  //           data = prevData.map((entry) =>
  //             Object.keys(entry).includes(parentKey)
  //               ? { [parentKey]: updatedSubTopics }
  //               : entry
  //           );
  //         }
  //       } else {
  //         data = prevData.map((entry) =>
  //           Object.keys(entry).includes(parentKey)
  //             ? { [parentKey]: [...currentSubTopics, clickedSubTopic] }
  //             : entry
  //         );
  //       }
  //     } else {
  //       data = [...prevData, { [parentKey]: [clickedSubTopic] }];
  //     }
  //     return data;
  //   });

  //   console.log(
  //     onboardingData
  //       .filter((item) => item.subTopics.includes(clickedSubTopic))
  //       .map((item) => item.topic)
  //   );
  // };

  const handleSubTopicData = (clickedSubTopic, parentIndex) => {
    setSelectSubTopics((prevData) => {
      const parentKey = parentIndex.toString();
      let updatedSubTopicsData;

      const existingEntry = prevData.find((entry) =>
        Object.keys(entry).includes(parentKey)
      );

      if (existingEntry) {
        const currentSubTopics = existingEntry[parentKey];

        if (currentSubTopics.includes(clickedSubTopic)) {
          const filteredSubTopics = currentSubTopics.filter(
            (subTopic) => subTopic !== clickedSubTopic
          );

          if (filteredSubTopics.length === 0) {
            updatedSubTopicsData = prevData.filter(
              (entry) => !Object.keys(entry).includes(parentKey)
            );
          } else {
            updatedSubTopicsData = prevData.map((entry) =>
              Object.keys(entry).includes(parentKey)
                ? { [parentKey]: filteredSubTopics }
                : entry
            );
          }
        } else {
          updatedSubTopicsData = prevData.map((entry) =>
            Object.keys(entry).includes(parentKey)
              ? { [parentKey]: [...currentSubTopics, clickedSubTopic] }
              : entry
          );
        }
      } else {
        updatedSubTopicsData = [
          ...prevData,
          { [parentKey]: [clickedSubTopic] },
        ];
      }

      const topicsToUpdate = onboardingData
        .filter((item) =>
          updatedSubTopicsData
            .flatMap((entry) => Object.values(entry).flat())
            .some((subTopic) => item.subTopics.includes(subTopic))
        )
        .map((item) => item.topic);

      setSelectedTopics(topicsToUpdate);

      return updatedSubTopicsData;
    });
  };

  useEffect(() => {
    sessionStorage.setItem("subTopics", JSON.stringify(selectSubTopics));
    sessionStorage.setItem("Topics", JSON.stringify(selectedTopics));
  }, [selectedTopics, selectSubTopics]);

  const modifiedData = selectSubTopics.flatMap((item) =>
    Object.values(item).flat()
  );

  const getArrayByKey = (key) => {
    const result = selectSubTopics.find((item) => item[key]);
    return result ? result[key] : [];
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <div className="bg-dark h-full rounded-lg flex justify-center overflow-y-scroll scrollbar-none">
        <div className="max-w-4xl mx-auto justify-center flex-col flex mb-auto">
          <div className="sio-card bg-white-gradient">
            <div className="mb-2 mt-3 flex">
              {/* <SioIcon name="leftArrow" className="text-white" size={20} /> */}
              <svg width="32" height="32" viewBox="0 0 32 32" className="me-3" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.4332 17.3332L16.9666 23.8666C17.2332 24.1332 17.361 24.4443 17.3499 24.7999C17.3388 25.1555 17.1999 25.4666 16.9332 25.7332C16.6666 25.9777 16.3555 26.1055 15.9999 26.1166C15.6443 26.1277 15.3332 25.9999 15.0666 25.7332L6.26657 16.9332C6.13324 16.7999 6.03879 16.6555 5.98324 16.4999C5.92768 16.3443 5.8999 16.1777 5.8999 15.9999C5.8999 15.8221 5.92768 15.6555 5.98324 15.4999C6.03879 15.3443 6.13324 15.1999 6.26657 15.0666L15.0666 6.26657C15.311 6.02212 15.6166 5.8999 15.9832 5.8999C16.3499 5.8999 16.6666 6.02212 16.9332 6.26657C17.1999 6.53324 17.3332 6.8499 17.3332 7.21657C17.3332 7.58324 17.1999 7.8999 16.9332 8.16657L10.4332 14.6666H25.3332C25.711 14.6666 26.0277 14.7943 26.2832 15.0499C26.5388 15.3055 26.6666 15.6221 26.6666 15.9999C26.6666 16.3777 26.5388 16.6943 26.2832 16.9499C26.0277 17.2055 25.711 17.3332 25.3332 17.3332H10.4332Z" fill="#F7F5F2"/>
              </svg>
              <div>
                <h1 className="font-bold">Saved Data Topics</h1>
                <p className="text-secondary-light text-xs sm:text-sm">
                  Choose your preferred topics to get personalized suggestions.
                </p>
              </div>
            </div>
            <div>
              <h5 className="text-xs uppercase text-primary-800 font-bold mb-2">
                TOPICs
              </h5>
              <div className="grid grid-cols-1 md:grid-cols-5 gap-2 md:gap-4">
                <div
                  className={`sio-card-icon-dark ${
                    getArrayByKey("0").length && "active"
                  }`}
                  onClick={() => {
                    handleSelectedTopicData("Student Information System (SIS)");
                  }}
                >
                  <div
                    className={`sio-avatar ${
                      getArrayByKey("0").length > 0 &&
                      "relative overflow-visible"
                    }`}
                  >
                    {getArrayByKey("0").length > 0 && (
                      <span className="bg-warning ml-1 absolute right-0 top-0 rounded-full size-4 inline-flex text-xs font-extrabold text-secondary-dark items-center justify-center">
                        {getArrayByKey("0").length}
                      </span>
                    )}
                    <SioIcon className="opacity-55" name="sis" size={18} />
                  </div>
                  <p className="text-secondary-light text-xs">
                    Student Information System (SIS)
                  </p>
                </div>
                <div
                  className={`sio-card-icon-dark ${
                    getArrayByKey("1").length && "active"
                  }`}
                  onClick={() => {
                    handleSelectedTopicData("Assessments");
                  }}
                >
                  <div
                    className={`sio-avatar ${
                      getArrayByKey("1").length > 0 &&
                      "relative overflow-visible"
                    }`}
                  >
                    {getArrayByKey("1").length > 0 && (
                      <span className="bg-warning ml-1 absolute right-0 top-0 rounded-full size-4 inline-flex text-xs font-extrabold text-secondary-dark items-center justify-center">
                        {getArrayByKey("1").length}
                      </span>
                    )}
                    <SioIcon className="opacity-55" name="assessments" size={18} />
                  </div>
                  <p className="text-secondary-light text-xs">Assessments</p>
                </div>
                <div
                  className={`sio-card-icon-dark ${
                    getArrayByKey("2").length && "active"
                  }`}
                  onClick={() => {
                    handleSelectedTopicData("Special Education");
                  }}
                >
                  <div
                    className={`sio-avatar ${
                      getArrayByKey("2").length > 0 &&
                      "relative overflow-visible"
                    }`}
                  >
                    {getArrayByKey("2").length > 0 && (
                      <span className="bg-warning ml-1 absolute right-0 top-0 rounded-full size-4 inline-flex text-xs font-extrabold text-secondary-dark items-center justify-center">
                        {getArrayByKey("2").length}
                      </span>
                    )}
                    <SioIcon className="opacity-55" name="specialEducation" size={18} />
                  </div>
                  <p className="text-secondary-light text-xs">
                    Special Education
                  </p>
                </div>
                <div
                  className={`sio-card-icon-dark ${ getArrayByKey("3").length && "active" }`}
                  onClick={() => {
                    handleSelectedTopicData("Behavior Systems");
                  }}
                >
                  <div
                    className={`sio-avatar ${
                      getArrayByKey("3").length > 0 &&
                      "relative overflow-visible"
                    }`}
                  >
                    {getArrayByKey("3").length > 0 && (
                      <span className="bg-warning ml-1 absolute right-0 top-0 rounded-full size-4 inline-flex text-xs font-extrabold text-secondary-dark items-center justify-center">
                        {getArrayByKey("3").length}
                      </span>
                    )}
                    <SioIcon className="opacity-55" name="behaviorSystems" size={18} />
                  </div>
                  <p className="text-secondary-light text-xs">
                    Behavior Systems
                  </p>
                </div>
                <div
                  className={`sio-card-icon-dark ${
                    getArrayByKey("4").length && "active"
                  }`}
                  onClick={() => {
                    handleSelectedTopicData(
                      "Learning Management Systems (LMS)"
                    );
                  }}
                >
                  <div
                    className={`sio-avatar ${
                      getArrayByKey("4").length > 0 &&
                      "relative overflow-visible"
                    }`}
                  >
                    {getArrayByKey("4").length > 0 && (
                      <span className="bg-warning ml-1 absolute right-0 top-0 rounded-full size-4 inline-flex text-xs font-extrabold text-secondary-dark items-center justify-center">
                        {getArrayByKey("4").length}
                      </span>
                    )}
                    <SioIcon className="opacity-55" name="lms" size={18} />
                  </div>
                  <p className="text-secondary-light text-xs">
                    Learning Management Systems (LMS)
                  </p>
                </div>
              </div>
            </div>
            <div>
              <h5 className="text-xs uppercase text-primary-800 font-bold mb-2">
                Choose SUB-TOPICs
              </h5>
              <div className="flex gap-2 flex-wrap">
                {onboardingData.map((data) =>
                  data.subTopics.map((item) => (
                    <button
                      className={`sio-badge-dark-link ${
                        modifiedData.includes(item) && "active"
                      }`}
                      onClick={() => {
                        const index = onboardingData.findIndex((value) =>
                          value.subTopics.includes(item)
                        );

                        handleSubTopicData(item, index);
                      }}
                    >
                      {item}
                    </button>
                  ))
                )}
              </div>
            </div>
          </div>
          <div className="sio-card bg-secondary-gradient xs:-mt-5 sm:-mt-12 xs:pt-10 sm:pt-20 flex flex-row items-center z-0 relative">
            <Button
              variant="secondary"
              className="ml-auto"
              size="small"
              onClick={async () => {
                try {
                  setIsGetStartedClicked(true);
                  setUpdatedOnboardingData(true);

                  await saveUserPreferences(selectedTopics, modifiedData);
                  sessionStorage.setItem("filesData", JSON.stringify(true));
                  sessionStorage.setItem(
                    "subTopics",
                    JSON.stringify(selectSubTopics)
                  );
                  sessionStorage.setItem(
                    "Topics",
                    JSON.stringify(selectedTopics)
                  );

                  navigate("/");
                } catch (err) {
                  console.error("Error saving user preferences", err);
                  alert(
                    "An error occurred while saving your preferences. Please try again later."
                  );
                }
              }}
            >
              Save Preferences
            </Button>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default SavedDataTopics;
