/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
// /* eslint-disable no-unused-vars */
import { Box, CircularProgress, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { computeSignature, getUserNames } from "../utils";
import { devApis } from "../constants/constant";
import {
  addMessageToSession,
  getFilesByTopicsWise,
  getFilesList,
} from "../apis/recommendation/recommendation";
import { checkAndRefreshToken } from "../utils/authUtils";
import { DataContext } from "./layout/layout";
import Cookies from "js-cookie";
import SioIcon from "./icons/IconLibrary";
import { StrivedContext } from "../App";

const ChatInputField = ({ handleHomePage, setIsLoading, isLoading }) => {
  const [file, setFile] = useState(null);
  const [prompt, setPrompt] = useState(null);
  const [fileNames, setFileNames] = useState(null);

  const { setSendUploadedFileData, handleDataFromLanding } =
    useContext(DataContext);

  const {
    isGetStartedClicked,
    setIsGetStartedClicked,
    selectSubTopics,
    selectedTopics,
  } = useContext(StrivedContext);

  useEffect(() => {
    getFileNames();
  }, []);

  let fileRes;

  const navigate = useNavigate();

  const handlePrompt = (e) => {
    setPrompt(e.target.value);
  };

  const getFileNames = async () => {
    const files = await getFilesList();
    const fileNames = files.map((file) => file.original_file_name);
    setFileNames(fileNames);
  };

  const fileUploadRequest = async () => {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      const getUUID = await getUserNames();
      const orgUuid = getUUID.data.org_uuid;
      formData.append("org_uuid", orgUuid);
      const secretKey = "wBLm_uRNsyuxHWZrIufExXRBI-uE0aDkjT7xsKLbn70=";

      const fileContent = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          const binaryString = reader.result;
          resolve(binaryString);
        };
        reader.onerror = reject;
        reader.readAsBinaryString(file);
      });

      const fileUploadsignature = computeSignature(
        secretKey,
        "POST",
        "/upload_file",
        {},
        { org_uuid: orgUuid },
        "",
        fileContent
      );
      try {
        const fileUploadResponse = await fetch(
          `${devApis.PYTHON_BASE_URL}/upload_file`,
          {
            method: "POST",
            headers: {
              "x-signature": fileUploadsignature,
              Authorization: `Bearer ${Cookies.get("token")}`,
            },
            body: formData,
          }
        );

        const fileUploadData = await fileUploadResponse.json();
        fileRes = fileUploadData;
        setSendUploadedFileData(fileRes);
        const response = await fetch(
          `${devApis.PYTHON_BASE_URL}/convert_csv_to_table`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${Cookies.get("token")}`,
            },
            body: formData,
          }
        );

        await response.json();

        setIsLoading(false);
      } catch (error) {
        toast.error("error:", error.message, {
          icon: <SioIcon name="close" size={16} color="blue" />,
        });
        setIsLoading(false);
      }
    } else {
      try {
        const promptResponse = await fetch(
          `${devApis.PYTHON_BASE_URL}/start_conversation`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Cookies.get("token")}`,
            },
            body: JSON.stringify({
              recommendation_id: 0,
            }),
          }
        );

        const data = await promptResponse.json();
        const newSessionId = data.session_id;

        if (newSessionId) {
          const token = await checkAndRefreshToken();
          const response = await addMessageToSession(
            newSessionId,
            prompt,
            token,
            fileNames
          );

          handleHomePage(prompt);
          setIsLoading(false);
          handleDataFromLanding({ recommendations: [] }, []);
          navigate("/askmeanything", {
            state: {
              sessionId: newSessionId,
              promptData: response?.answer,
              promptInput: prompt,
              fileName: fileNames,
              aiChildRecommendation: [
                response?.qualitative_question,
                response?.quantitative_question,
              ],
              message_id: response?.message_id,
            },
          });
        }
      } catch (error) {
        console.error("Error during API calls:", error);
        toast.error("error:", error.message, {
          icon: <SioIcon name="close" size={16} color="blue" />,
        });
        return null;
      }
    }

    setFile(null);
  };

  const handleSubmit = async () => {
    if (!prompt || prompt.trim().length === 0) {
      toast("Please provide valid input", {
        icon: <SioIcon name="close" size={16} color="blue" />,
      });
      return;
    }
    setIsLoading(true);
    await fileUploadRequest();
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      handleSubmit();
    } else if (e.key === "Enter" && e.shiftKey) {
      return;
    }
  };

  return (
    <div className="flex flex-col w-full">
      {isLoading ? (
        <div className="flex justify-center items-center">
          <Box sx={{ display: "flex" }}>
            <CircularProgress />
          </Box>
        </div>
      ) : (
        <>
          <div className="absolute top-3 left-0 w-full -mt-2"></div>
          <div className="flex items-center justify-between">
            <TextField
              className="border-none w-full text-md focus:outline-none"
              multiline
              minRows={1}
              maxRows={8}
              placeholder="Ask me anything..."
              onChange={handlePrompt}
              onKeyDown={handleKeyDown}
              autoComplete="off"
              sx={{
                "& .MuiOutlinedInput-root": {
                  padding: "8px 8px 8px 16px",
                  "& fieldset": {
                    border: "none",
                  },
                },
              }}
            />
            <button
              className="soi-btn soi-btn-fab btn-primary shrink-0 my-2 me-2 self-end"
              type="button"
              onClick={handleSubmit}
            >
              <SioIcon name="submit" size={16} />
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default ChatInputField;
