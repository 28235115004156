import React, { useEffect, useState } from "react";
import { HotTable } from "@handsontable/react";

const CSVViewer = () => {
  // State for parsed data, column headers, and file name
  const [parsedData, setParsedData] = useState(() => {
    const storedData = sessionStorage.getItem("parsedData");
    return storedData ? JSON.parse(storedData) : [];
  });

  const [columnHeaders, setColumnHeaders] = useState(() => {
    const storedColumns = sessionStorage.getItem("columnHeaders");
    return storedColumns ? JSON.parse(storedColumns) : [];
  });

  const [fileName, setFileName] = useState(() => {
    return sessionStorage.getItem("fileName") || "";
  });

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.origin === window.location.origin && event.data) {
        const { data, columns, fileName } = event.data;

        if (data && columns) {
          setParsedData(data);
          setColumnHeaders(columns);
          setFileName(fileName);
        }
      }
    };

    // Add event listener for postMessage
    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  return (
    <div className="bg-white h-full rounded-lg flex flex-col items-center">
      {fileName && (
        <h2 className="text-xl font-bold mb-4 text-center ">
          Viewing File: <h1 className="text-primary-800">{fileName}</h1>
        </h2>
      )}
      {parsedData.length > 0 && columnHeaders.length > 0 ? (
        <HotTable
          data={parsedData}
          colHeaders={columnHeaders}
          rowHeaders={true}
          licenseKey="non-commercial-and-evaluation"
          stretchH="all"
          height={"100%"}
          width={"100%"}
          colWidths={() => {
            const numColumns = columnHeaders.length;
            const baseWidth = 100;
            const maxWidth = 150;
            const totalWidth = window.innerWidth;

            const calculatedWidth = Math.min(totalWidth / numColumns, maxWidth);

            return Math.max(calculatedWidth, baseWidth);
          }}
          className="handsontable"
          afterGetColHeader={(col, TH) => {
            if (!TH.classList.contains("custom-header")) {
              TH.classList.add("custom-header");
            }
          }}
          cells={(row, col, prop) => {
            return {
              className: row === -1 ? "header-highlight" : "",
              readOnly: true,
            };
          }}
        />
      ) : (
        <p>Loading data...</p>
      )}
    </div>
  );
};

export default CSVViewer;
